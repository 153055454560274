import { selectUser } from "app/redux/userSlice";
import { useQuery } from "app/utils/useQuery";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export enum UserSettingTypes {
  DETAILS = "details",
  SECURITY = "security",
}

export const useUser = () => {
  const query = useQuery();
  const user = useSelector(selectUser);
  const path = query.get("path");
  const navigate = useNavigate();

  const onMenuPress = (key: string) => {
    navigate(`?path=${key}`);
  };

  useEffect(() => {
    if (!path) {
      navigate(`?path=${UserSettingTypes.DETAILS}`);
    }
  }, []);

  return { user, path, onMenuPress };
};
