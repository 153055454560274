import { SectionHeader } from "app/components/sectionHeader";
import * as React from "react";
import { useUserDetails } from "./useUserDetails";
import { InputEdit } from "app/components/inputEdit/InputEdit";
import { CredsConfirmModal } from "../credsConfirmModal/credsConfirmModal";
import { Avatar, Button, Divider } from "@nextui-org/react";

export interface UserDetailsProps {}

export const UserDetails: React.FC<UserDetailsProps> = ({}) => {
  const {
    user,
    onUpdateUser,
    showReAuthModal,
    setShowReAuthModal,
    handleReauthAndSubmit,
    handleAvatarChange,
  } = useUserDetails();

  return (
    <>
      <CredsConfirmModal
        isOpen={showReAuthModal}
        onOpenChange={setShowReAuthModal}
        onSubmit={handleReauthAndSubmit}
      />
      <div className="flex flex-col w-full gap-4">
        <SectionHeader title="User Details" />
        <div className="p-4 gap-4 flex flex-col rounded border border-[#E4E4E7]">
          <div className="flex flex-col gap-4">
            <div className="flex items-center">
              <Avatar className="w-14 h-14 rounded" src={user?.avatar} />
              <input
                type="file"
                accept="image/*"
                id="avatar"
                style={{ display: "none" }}
                onChange={handleAvatarChange}
              />
              <Button
                variant="bordered"
                className="ml-4 border-black rounded-sm text-sm font-semibold p-[14px] w-[208px]"
                onClick={() => document.getElementById("avatar")?.click()}
              >
                Upload picture
              </Button>
            </div>
            <Divider />
          </div>
          <div className="flex flex-col gap-10">
            <InputEdit
              defaultValue={user?.name}
              label="Name"
              name="name"
              onSave={(value) => onUpdateUser({ ...value })}
              valueClassName="font-semibold"
            />
            <InputEdit
              defaultValue={user?.email}
              label="Email"
              name="email"
              onSave={(value) => onUpdateUser({ ...value })}
              valueClassName="font-semibold"
            />
          </div>
        </div>
      </div>
    </>
  );
};
