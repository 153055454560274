import * as React from "react";
import { useAcceptInvite } from "./useAcceptInvite";
import { Button, Input, Spinner } from "@nextui-org/react";

export interface AcceptInviteProps {}

export const AcceptInvite: React.FC<AcceptInviteProps> = ({}) => {
  const { user, isLoading, isTokenValid, onSubmit } = useAcceptInvite();

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex flex-col gap-4 items-center justify-center">
        <h1 className="text-lg font-semibold">Validating Token</h1>
        <Spinner size="lg" />
      </div>
    );
  }

  if (!isTokenValid) {
    return (
      <div className="w-screen h-screen flex flex-col gap-4 items-center justify-center">
        <h1 className="text-lg font-semibold">Invalid Token</h1>
        <p>Please contact the sender for a new invite link</p>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen flex flex-col gap-4 items-center justify-center">
      <form
        className="flex flex-col gap-4 w-[600px] border-1 border-zinc-100 shadow-lg p-8 rounded-sm"
        onSubmit={onSubmit}
      >
        <div className="flex flex-col gap-4 mb-8 items-center">
          <h1 className="font-semibold text-lg">Welcome {user.name}</h1>
          <div className="flex flex-col gap-1 items-center">
            <p className="text-sm text-zinc-500">
              You've been invited by{" "}
              <span className="font-semibold">{user.account.name}</span>
            </p>
            <p className="text-sm text-zinc-500">
              Please enter your password below to complete sign up process
            </p>
          </div>
        </div>
        <Input
          classNames={{
            inputWrapper: "custom-input",
          }}
          labelPlacement="outside"
          variant="bordered"
          label="Name"
          name="name"
          placeholder="Enter name"
          validationBehavior="native"
          value={user?.name}
          isDisabled
        />
        <Input
          classNames={{
            inputWrapper: "custom-input",
          }}
          labelPlacement="outside"
          variant="bordered"
          label="Email"
          name="email"
          type="email"
          placeholder="Enter email"
          value={user?.email}
          validationBehavior="native"
          isDisabled
        />
        <Input
          classNames={{
            inputWrapper: "custom-input",
          }}
          labelPlacement="outside"
          variant="bordered"
          label="Password"
          name="password"
          isRequired
          placeholder="Enter password"
          validate={(value) =>
            value.length < 8 ? "Password is too short" : ""
          }
          description="Password must be at least 8 characters long"
          validationBehavior="native"
        />
        <Input
          classNames={{
            inputWrapper: "custom-input",
          }}
          labelPlacement="outside"
          variant="bordered"
          label="Confirm Password"
          name="passwordConfirmation"
          isRequired
          placeholder="Re-enter password above to confirm"
          validationBehavior="native"
        />
        <Button
          className="bg-black text-white rounded-sm w-full mt-4"
          type="submit"
        >
          Sign up
        </Button>
      </form>
    </div>
  );
};
