import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export const durationList = [
  { duration: 15, label: "15 min" },
  { duration: 20, label: "20 min" },
  { duration: 30, label: "30 min" },
  { duration: 45, label: "45 min" },
  { duration: 60, label: "1 hr" },
  { duration: 90, label: "1.5 hrs" },
  { duration: 120, label: "2 hrs" },
  { duration: 150, label: "2.5 hrs" },
  { duration: 180, label: "3 hrs" },
  { duration: 210, label: "3.5 hrs" },
  { duration: 240, label: "4 hrs" },
  { duration: 270, label: "4.5 hrs" },
  { duration: 300, label: "5 hrs" },
];

export interface BusinessServicesSliceState {
  id: number;
  name: string;
  services: any;
  createdAt: string;
  updatedAt: string;
}

const initialState = [] as BusinessServicesSliceState[];

export const getBusinessServices = createAsyncThunk(
  "businessServices/getBusinessServices",
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get("/v1/service_categories");
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createBusinessServiceCategory = createAsyncThunk(
  "businessServices/createBusinessServiceCategory",
  async (category: any, thunkAPI) => {
    try {
      const { data } = await http.post("/v1/service_categories", category);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createBusinessService = createAsyncThunk(
  "businessServices/createBusinessService",
  async (service: any, thunkAPI) => {
    try {
      const { data } = await http.post("/v1/services", service);
      // await thunkAPI.dispatch(getBusinessServices() as any);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateBusinessServiceCategory = createAsyncThunk(
  "businessServices/updateBusinessServiceCategory",
  async (category: any, thunkAPI) => {
    try {
      const { data } = await http.put(
        `/v1/service_categories/${category.id}`,
        category
      );
      await thunkAPI.dispatch(getBusinessServices() as any);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateBusinessService = createAsyncThunk(
  "businessServices/updateBusinessService",
  async (service: any, thunkAPI) => {
    try {
      const { data } = await http.put(`/v1/services/${service.id}`, service);
      await thunkAPI.dispatch(getBusinessServices() as any);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const updateBusinessService = createAsyncThunk(
// );

// export const deleteBusinessService = createAsyncThunk(
// );

export const businessServicesSlice = createSlice({
  name: "businessServices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBusinessServices.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(
      createBusinessServiceCategory.fulfilled,
      (state, action) => {
        const newState = [...state];
        return newState.concat(action.payload);
      }
    );
    builder.addCase(createBusinessService.fulfilled, (state, action) => {
      // Find the category to add the service to
      const newService = action.payload;
      return state.map((category) => {
        return category.id === newService.serviceCategory.id
          ? { ...category, services: [...category.services, newService] }
          : category;
      });
    });
    builder.addCase(
      updateBusinessServiceCategory.fulfilled,
      (state, action) => {
        // Find the category to update
        const updatedCategory = action.payload;
        return state.map((category) =>
          category.id === updatedCategory.id ? updatedCategory : category
        );
      }
    );
    builder.addCase(updateBusinessService.fulfilled, (state, action) => {
      // Find the category and service to update
      const updatedService = action.payload;
      return state.map((category) => {
        const services = category.services.map((service: any) =>
          service.id === updatedService.id ? updatedService : service
        );
        return { ...category, services };
      });
    });
  },
});

export const selectBusinessServices = (state: RootState) =>
  state.businessServices;
