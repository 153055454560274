import * as React from "react";
import { useHowManyServiceProviders } from "./useHowManyServiceProviders";
import { ChoiceCard } from "./components/choiceCard";
import { Button, Spinner } from "@nextui-org/react";

export interface HowManyServiceProvidersProps {
  step: number;
  totalSteps: number;
  setCurrentStep: (step: string) => void;
}

const choiceCardData = [
  {
    amount: 1,
    title: "Just me",
    description: "1",
  },
  {
    amount: 3,
    title: "Small",
    description: "2-4",
  },
  {
    amount: 8,
    title: "Medium",
    description: "5-15",
  },
  {
    amount: 24,
    title: "Large",
    description: "16+",
  },
];

export const HowManyServiceProviders: React.FC<
  HowManyServiceProvidersProps
> = ({ step, totalSteps, setCurrentStep }) => {
  const { teamSize, setTeamSize, onSubmit, loading } =
    useHowManyServiceProviders({
      setCurrentStep,
    });
  return (
    <div className="flex flex-col mt-[6%] items-center m-auto w-full h-screen max-w-[945px]">
      <div className="bg-[#353535] h-6 w-6 rounded-full mb-16" />
      <div className="flex flex-col gap-4 items-center mb-12">
        <p className="text-zinc-500 text-sm">
          Step {step} of {totalSteps}
        </p>
        <h1 className="text-zinc-700 text-2xl font-semibold">
          How many service providers do you have?
        </h1>
      </div>
      <div className="flex gap-12 mb-16">
        {choiceCardData.map((choiceCard) => (
          <ChoiceCard
            key={choiceCard.amount}
            amount={choiceCard.amount}
            title={choiceCard.title}
            description={choiceCard.description}
            teamSize={teamSize}
            setTeamSize={setTeamSize}
          />
        ))}
      </div>
      <Button
        isDisabled={!teamSize}
        className="w-full max-w-[400px] p-3 rounded text-white bg-black"
        onClick={() => onSubmit()}
      >
        {loading && <Spinner size="sm" color="default" />}
        Continue
      </Button>
    </div>
  );
};
