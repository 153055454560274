import React from "react";
import { format } from "date-fns";
import { CalendarDayNavigateTypes } from "app/schedules/useSchedules";
import { Button } from "@nextui-org/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import Select from "react-select";
import { useCustomToolbar } from "./useCustomToolbar";
import { ShopsSliceState } from "app/redux/shopsSlice";

export const CustomToolbar: React.FC<any> = ({ date, onNavigate }) => {
  const { onShopsChange, goToBack, goToNext, goToToday, shops, shop } =
    useCustomToolbar({
      onNavigate,
    });

  return (
    <div className="flex items-center relative justify-center">
      <Select
        key={`shop-select-${shop?.id}`}
        className="w-52 !absolute left-4 z-[100] text-sm"
        options={shops.map((shop) => ({
          value: shop.id,
          label: shop.name,
          complete: shop?.latitude && shop?.longitude && shop.addressLine1,
        }))}
        onChange={onShopsChange}
        placeholder="Select Shop"
        defaultValue={{
          value: shop?.id,
          label: shop?.name,
        }}
        isOptionDisabled={(option: any) => !option.complete}
      />
      <div className="flex self-center justify-between my-5 items-center bg-white w-full max-w-[320px] border-gray-300">
        <Button
          variant="light"
          isIconOnly
          onClick={goToBack}
          className=" text-gray-600 h-4 w-4 !rounded"
        >
          <ChevronLeftIcon />
        </Button>
        <span className="font-semibold text-zinc-700">
          {format(date, "EEE, MMM dd")}
        </span>
        <Button
          variant="light"
          isIconOnly
          onClick={goToNext}
          className=" text-gray-600 h-4 w-4 !rounded"
        >
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
};
