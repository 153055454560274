import * as React from "react";

export interface CustomServiceOptionProps {
  innerRef: any;
  innerProps: any;
  data: any;
}

export const CustomServiceOption: React.FC<CustomServiceOptionProps> = ({
  innerRef,
  innerProps,
  data,
}) => {
  const isInactive = data.status === "inactive";
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`flex items-center gap-2 p-2 px-4 cursor-pointer ${
        isInactive ? "bg-gray-200 cursor-not-allowed" : "hover:bg-blue-200"
      }`}
      style={{ pointerEvents: isInactive ? "none" : "auto" }}
    >
      <div className="flex justify-between w-full gap-1">
        <p className="flex flex-col gap-1">
          <span
            className={`font-bold text-sm ${
              isInactive ? "text-gray-400" : "text-zinc-700"
            }`}
          >
            {data.label}
          </span>
          <span className="text-xs text-zinc-500">
            {isInactive &&
              `${data.employee.name} does not provide this service`}
          </span>
        </p>
      </div>
    </div>
  );
};
