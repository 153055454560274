import * as React from "react";
import { useOnboarding } from "./useOnboarding";
import { snakeCase } from "lodash";
import { OnboardingStep } from "app/redux/onboardingSlice";
import { Path } from "app/path";
import { BusinessInfo } from "./screens/businessInfo/businessInfo";
import { HowManyServiceProviders } from "./screens/howManyServiceProviders/howManyServiceProviders";
import { PaymentApiKeys } from "./screens/paymentApiKeys/paymentApiKeys";

export interface OnboardingProps {}

const getCurrentStep = (
  currentStep: string,
  setCurrentStep: (step: string) => void,
  navigate: any
) => {
  switch (snakeCase(currentStep)) {
    case OnboardingStep.BUSINESS_INFO:
      return (
        <BusinessInfo step={1} totalSteps={3} setCurrentStep={setCurrentStep} />
      );
    case OnboardingStep.HOW_MANY_SERVICE_PROVIDERS:
      return (
        <HowManyServiceProviders
          step={2}
          totalSteps={3}
          setCurrentStep={setCurrentStep}
        />
      );
    case OnboardingStep.PAYMENT_API_KEYS:
      return (
        <PaymentApiKeys
          step={3}
          totalSteps={3}
          setCurrentStep={setCurrentStep}
        />
      );
    case OnboardingStep.COMPLETE:
      return navigate(Path.SETTINGS);
    default:
      return null;
  }
};

export const Onboarding: React.FC<OnboardingProps> = (props) => {
  const { currentStep, setCurrentStep, navigate } = useOnboarding();

  const screen = getCurrentStep(currentStep!, setCurrentStep, navigate);

  return screen;
};
