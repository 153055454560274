import { getAccount, updateAccount } from "app/redux/accountSlice";
import { OnboardingStep, updateOnboarding } from "app/redux/onboardingSlice";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useHowManyServiceProviders = ({
  setCurrentStep,
}: {
  setCurrentStep: (step: string) => void;
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [teamSize, setTeamSize] = useState<string>();

  const onSubmit = async () => {
    setLoading(true);
    try {
      await dispatch(
        updateAccount({
          teamSize,
        }) as any
      ).unwrap();

      await dispatch(
        updateOnboarding({
          step: OnboardingStep.PAYMENT_API_KEYS,
        }) as any
      ).unwrap();
      await dispatch(getAccount() as any).unwrap();
      setLoading(false);
      setCurrentStep(OnboardingStep.PAYMENT_API_KEYS);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
    // setCurrentStep("complete");
  };

  return {
    teamSize,
    setTeamSize,
    onSubmit,
    loading,
  };
};
