import {
  createClient,
  selectClients,
  updateClient,
} from "app/redux/clientsSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getFormValues } from "app/utils/getFormValues";
import { find } from "lodash";
import { FormEvent, useEffect, useState } from "react";

export const useClientDrawer = ({
  clientId,
  setCloseParent,
}: {
  clientId?: number;
  setCloseParent?: (value: boolean) => void;
}) => {
  const { clients } = useSelector(selectClients);
  const dispatch = useDispatch();
  const [isCreatingClient, setIsCreatingClient] = useState(false);
  const [profileImagePreview, setProfileImagePreview] = useState<string | null>(
    null
  );
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);
  const client = find(clients, { id: clientId });
  const [newNoteContent, setNewNoteContent] = useState<string>("");
  const [showNoteTextArea, setShowNoteTextArea] = useState<boolean>(false);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsCreatingClient(true);
    const { firstName, lastName, email, phone, gender, note } = getFormValues(
      event.target as HTMLFormElement
    );

    const payload = {
      name: `${firstName} ${lastName}`,
      email,
      phone,
      gender,
      note,
    };

    try {
      const client = await dispatch(createClient(payload) as any).unwrap();

      if (profileImageFile) {
        await handleAvatarUpload(client.id); // Handle avatar upload after creating client
      }

      setIsCreatingClient(false);
      setCloseParent?.(false);
      toast.success("Client created successfully");
    } catch (error) {
      setIsCreatingClient(false);
      console.error(error);
      toast.error("Failed to create client");
    }
  };

  const handleAvatarUpload = async (clientId?: number, file?: any) => {
    const imageFileToUser = file || profileImageFile;

    if (clientId && imageFileToUser) {
      const formData = new FormData();
      formData.append("client[avatar]", imageFileToUser);

      try {
        await dispatch(
          updateClient({
            id: clientId,
            avatar: imageFileToUser,
          }) as any
        ).unwrap();
        toast.success("Profile image updated successfully");
      } catch (error: any) {
        console.error(
          "Error updating profile image:",
          error.response || error.message || error
        );
        toast.error("Failed to update profile image");
      }
    }
  };

  const onUserUpdate = async ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => {
    setIsCreatingClient(true);

    let finalKey = key;
    let finalValue = value;
    if (key === "firstName") {
      finalKey = "name";
      finalValue = `${value} ${client!.name.split(" ")[1]}`;
    } else if (key === "lastName") {
      finalKey = "name";
      finalValue = `${client!.name.split(" ")[0]} ${value}`;
    }

    try {
      await dispatch(
        updateClient({
          id: client!.id,
          [finalKey]: finalValue,
        } as any) as any
      ).unwrap();

      if (profileImageFile) {
        await handleAvatarUpload(client!.id); // Handle avatar upload after updating client
      }

      setIsCreatingClient(false);
      toast.success("Client updated successfully");
    } catch (error) {
      setIsCreatingClient(false);
      console.error(error);
      toast.error("Failed to update client");
    }
  };

  const handleProfileImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    submitNow = false
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImagePreview(imageUrl);
      setProfileImageFile(file); // Store the file in state
    }
    if (submitNow) {
      await handleAvatarUpload(client?.id, file);
    }
  };

  const addNewNote = async () => {
    if (client) {
      try {
        await dispatch(
          updateClient({
            id: client.id,
            note: newNoteContent,
          } as any) as any
        ).unwrap();
        toast.success("Note added successfully");
      } catch (error) {
        console.error(error);
        toast.error("Failed to add note");
      }
    }
  };

  useEffect(() => {
    return () => {
      if (profileImagePreview) URL.revokeObjectURL(profileImagePreview);
    };
  }, [profileImagePreview]);

  useEffect(() => {
    return () => {
      setShowNoteTextArea(false);
    };
  }, [client]);

  return {
    onSubmit,
    isCreatingClient,
    onUserUpdate,
    clients,
    client,
    profileImagePreview,
    handleProfileImageChange,
    showNoteTextArea,
    setShowNoteTextArea,
    addNewNote,
    newNoteContent,
    setNewNoteContent,
  };
};
