import { Path } from "app/path";
import { selectShops } from "app/redux/shopsSlice";
import { CalendarDayNavigateTypes } from "app/schedules/useSchedules";
import { useQuery } from "app/utils/useQuery";
import { find } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const useCustomToolbar = ({
  onNavigate,
}: {
  onNavigate: (type: CalendarDayNavigateTypes) => void;
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const shopId = query.get("shopId");
  const shops = useSelector(selectShops);
  const shop = find(shops, { id: Number(shopId) });

  const goToBack = () => {
    onNavigate(CalendarDayNavigateTypes.PREV);
  };

  const goToNext = () => {
    onNavigate(CalendarDayNavigateTypes.NEXT);
  };

  const goToToday = () => {
    onNavigate(CalendarDayNavigateTypes.TODAY);
  };

  const onShopsChange = (selectedOption: any) => {
    query.delete("shopId");
    query.append("shopId", selectedOption.value);
    navigate(`${Path.HOME}?${query.toString()}`);
  };

  return { onShopsChange, goToBack, goToNext, goToToday, shops, shop };
};
