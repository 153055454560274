import { Button, Input, Spinner } from "@nextui-org/react";
import * as React from "react";
import { usePaymentApiKeys } from "./usePaymentApiKeys";

export interface PaymentApiKeysProps {
  step: number;
  totalSteps: number;
  setCurrentStep: (step: string) => void;
}

export const PaymentApiKeys: React.FC<PaymentApiKeysProps> = ({
  step,
  totalSteps,
  setCurrentStep,
}) => {
  const { loading, onSubmit } = usePaymentApiKeys({ setCurrentStep });

  return (
    <div className="flex flex-col mt-[6%] items-center m-auto w-full h-screen max-w-[500px]">
      <div className="bg-[#353535] h-6 w-6 rounded-full mb-16" />
      <div className="flex flex-col gap-4 items-center mb-12">
        <p className="text-zinc-500 text-sm">
          Step {step} of {totalSteps}
        </p>
        <h1 className="text-zinc-700 text-2xl font-semibold">
          Please enter your payment API keys
        </h1>
      </div>
      <form onSubmit={onSubmit} className="w-full flex flex-col gap-10">
        <Input
          label="API Key"
          name="apiKey"
          labelPlacement="outside"
          placeholder="Enter your API key"
          isRequired
          validationBehavior="native"
          classNames={{
            label: "font-semibold !text-zinc-700",
            inputWrapper: "custom-input",
          }}
        />
        <Input
          label="API Pin"
          labelPlacement="outside"
          name="apiPin"
          placeholder="Enter your API pin"
          isRequired
          validationBehavior="native"
          classNames={{
            label: "font-semibold !text-zinc-700",
            inputWrapper: "custom-input",
          }}
        />
        <Input
          label="Tokenization Key"
          labelPlacement="outside"
          name="apiTokenizationKey"
          placeholder="Enter your tokenization key"
          isRequired
          validationBehavior="native"
          classNames={{
            label: "font-semibold !text-zinc-700",
            inputWrapper: "custom-input",
          }}
        />
        <Button className="w-full rounded text-white bg-black" type="submit">
          {loading && <Spinner size="sm" color="default" />}
          Finish
        </Button>
      </form>
    </div>
  );
};
