import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "app/requests/axiosInstance";
import { getEmployees } from "./employeesSlice";

export interface MerchantServicesSliceState {
  id: number;
  businessService: any;
  serviceCategory: any;
  status: string;
  priceCents: number;
  duration: number;
  createdAt: string;
  updatedAt: string;
}

// const initialState = [] as MerchantServicesSliceState[];

// export const getMerchantServices = createAsyncThunk(

// );

// export const createMerchantService = createAsyncThunk(
// );

export const updateMerchantService = createAsyncThunk(
  "merchantServices/updateMerchantService",
  async (service: MerchantServicesSliceState, thunkAPI) => {
    try {
      const { data } = await http.put(
        `/v1/merchant_services/${service.id}`,
        service
      );
      await thunkAPI.dispatch(getEmployees());
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const deleteMerchantService = createAsyncThunk(
// );

// export const merchantServicesSlice = createSlice({
//   name: "merchantServices",
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder.addCase(updateMerchantService.fulfilled, (state, action) => {
//       const index = state.findIndex(
//         (service) => service.id === action.payload.id
//       );
//       state[index] = action.payload;
//     });
//     builder.addCase(deleteMerchantService.fulfilled, (state, action) => {
//       return state.filter((service) => service.id !== action.payload.id);
//     });
//   },
// });

// export const selectMerchantServices = (state: RootState) =>
//   state.merchantServices;
