import * as React from "react";
import { useQuery } from "app/utils/useQuery";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { find } from "lodash";
import { getEmployees, selectEmployees } from "app/redux/employeesSlice";
import { resendUserInvite } from "app/redux/userSlice";
import { toast } from "react-toastify";

export enum StaffSettingTypes {
  STAFF_DETAILS = "staffDetails",
  SERVICES = "services",
  HOURS = "hours",
}

const buildUrl = (path: string, employeeId: number) => {
  const baseUrl = `?path=${path}`;
  if (employeeId) {
    return `${baseUrl}&employeeId=${employeeId}`;
  }
  return baseUrl;
};

export const useStaff = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const path = query.get("path");
  const employeeId = Number(query.get("employeeId"));
  const employees = useSelector(selectEmployees);
  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [resendCooldown, setResendCooldown] = useState<number | null>(null);
  const resendTimeoutRef = useRef<any>();

  const getSelectedEmployee = () => {
    return find(employees, { id: employeeId });
  };

  const onMenuPress = (key: string) => {
    navigate(buildUrl(key, employeeId));
  };

  const onEmployeePress = ({ employeeId }: { employeeId: number }) => {
    navigate(buildUrl(path!, employeeId));
  };

  const onResendInvite = async (employee: any) => {
    if (resendCooldown !== null) {
      return; // Prevent resending if the cooldown is active
    }

    try {
      await dispatch(resendUserInvite({ id: employee.id }) as any).unwrap();
      toast.success(`Invite resent successfully to ${employee.name}`);
      setResendCooldown(10); // Start cooldown at 10 seconds

      resendTimeoutRef.current = setInterval(() => {
        setResendCooldown((prevCooldown) => {
          if (prevCooldown === 1) {
            clearInterval(resendTimeoutRef.current);
            return null;
          }
          return prevCooldown! - 1;
        });
      }, 1000);
    } catch (error: any) {
      toast.error("An error occurred while resending the invite");
      console.error(error);
    }
  };

  useEffect(() => {
    // Cleanup on component unmount
    return () => {
      if (resendTimeoutRef.current) {
        clearInterval(resendTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Check if the path is not present
    if (!path && employees.length > 0) {
      navigate(`?path=${StaffSettingTypes.STAFF_DETAILS}`);
    } else {
      if (!employeeId && employees.length > 0) {
        navigate(buildUrl(StaffSettingTypes.STAFF_DETAILS, employees[0].id));
      }
    }
  }, [path]);

  useEffect(() => {
    // run only when employeeId and the new one is not the old one
    setSelectedEmployee(getSelectedEmployee());
  }, [employeeId, employees]);

  useEffect(() => {
    (async () => {
      await dispatch(getEmployees() as any).unwrap();
    })();
  }, []);

  return {
    onMenuPress,
    path,
    employees,
    onEmployeePress,
    selectedEmployee,
    isInviteModalOpen,
    setIsInviteModalOpen,
    onResendInvite,
    resendCooldown,
  };
};
