import * as React from "react";
import { useQuery } from "app/utils/useQuery";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export enum SettingTypes {
  BUSINESS_DETAILS = "businessDetails",
  LOCATION = "location",
  BRANDING = "branding",
  API_KEYS = "apiKeys",
}

export const useSettings = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const path = query.get("path");

  const onMenuPress = (key: string) => {
    navigate(`?path=${key}`);
  };

  useEffect(() => {
    if (!path) {
      navigate(`?path=${SettingTypes.BUSINESS_DETAILS}`);
    }
  }, [path]);

  return {
    onMenuPress,
    path,
  };
};
