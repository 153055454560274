import { UserAuth, UserContextType } from "app/context/AuthContext";
import { selectUser } from "app/redux/userSlice";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const useNavbar = () => {
  const user = useSelector(selectUser);
  const { logout } = UserAuth() as UserContextType;
  const location = useLocation();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [hoveredNavIcon, setHoveredNavIcon] = React.useState<string | null>(
    null
  );

  useEffect(() => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  }, [location]);

  return {
    isExpanded,
    setIsExpanded,
    navigate,
    hoveredNavIcon,
    setHoveredNavIcon,
    user,
    logout,
  };
};
