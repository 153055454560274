import { createShop, selectShops, updateShop } from "app/redux/shopsSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchLocation as searchLocationFromApi } from "app/redux/mapboxSlice";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { getFormValues, getInvalidKeys } from "app/utils/getFormValues";
import { toast } from "react-toastify";

interface LocationSuggestion {
  name: string;
  latitude: number;
  longitude: number;
}

interface InitialValues {
  email: string;
  phone: string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
}

type InitialValueKeys = keyof InitialValues;

interface LocationState {
  addressInputText: string;
  locationSuggestions: LocationSuggestion[];
  selectedLocation?: LocationSuggestion;
  initialValues: InitialValues;
  isDirty: boolean;
}

export const useLocations = () => {
  const dispatch = useDispatch();
  const existingShops = useSelector(selectShops);
  const [shops, setShops] = useState<any[]>([]);
  const [locationStates, setLocationStates] = useState<
    Record<number, LocationState>
  >({});
  const [isCreatingNewShop, setIsCreatingNewShop] = useState(false);
  const [openShops, setOpenShops] = useState<Record<number, boolean>>({});

  const searchLocations = async (shopId: number, search: string) => {
    if (!search) {
      setLocationStates((prev) => ({
        ...prev,
        [shopId]: {
          ...prev[shopId],
          locationSuggestions: [],
        },
      }));
      return;
    }
    try {
      const { data } = await dispatch(
        searchLocationFromApi({ query: search }) as any
      ).unwrap();
      setLocationStates((prev) => ({
        ...prev,
        [shopId]: {
          ...prev[shopId],
          locationSuggestions: data,
        },
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedSearchLocations = useCallback(
    debounce((shopId: number, search: string) => {
      searchLocations(shopId, search);
    }, 300),
    []
  );

  const onLocationInputChange = (shopId: number, search: string) => {
    setLocationStates((prev) => {
      const initialValue = prev[shopId]?.initialValues.address || "";
      const isDirty = search !== initialValue;

      return {
        ...prev,
        [shopId]: {
          ...prev[shopId],
          addressInputText: search,
          isDirty,
        },
      };
    });
    debouncedSearchLocations(shopId, search);
  };

  const onLocationSelect = (shopId: number, locationName: string) => {
    const selectedLocation = locationStates[shopId]?.locationSuggestions.find(
      (loc) => loc.name === locationName
    );
    if (selectedLocation) {
      setLocationStates((prev) => ({
        ...prev,
        [shopId]: {
          ...prev[shopId],
          selectedLocation,
          addressInputText: selectedLocation.name,
          locationSuggestions: [],
          isDirty:
            selectedLocation.name !== prev[shopId]?.initialValues.address,
        },
      }));
    }
  };

  const onShopArchiveToggle = async (shop: any, isArchived: boolean) => {
    const type = isArchived ? "unarchive" : "archive";
    const shouldToggleArchive = window.confirm(
      `Are you sure you want to ${type} the ${shop.name} location?`
    );
    if (!shouldToggleArchive) {
      return;
    }
    try {
      await dispatch(
        updateShop({
          id: shop.id,
          archivedAt: isArchived ? null : new Date().toISOString(),
        }) as any
      ).unwrap();

      setShops((prev) => prev.filter((prevShop) => prevShop.id !== shop.id));

      toast.success(`Location ${type}d successfully`);
    } catch (error) {
      console.log(error);
      toast.error(`Failed to ${type} location`);
    }
  };

  const onFormChange = (
    shopId: number,
    name: InitialValueKeys,
    value: string
  ) => {
    setLocationStates((prev) => {
      const initialValue = prev[shopId]?.initialValues[name] || "";
      const isDirty = value !== initialValue;

      return {
        ...prev,
        [shopId]: {
          ...prev[shopId],
          initialValues: {
            ...prev[shopId]?.initialValues,
            [name]: value,
          },
          isDirty: isDirty || prev[shopId].isDirty,
        },
      };
    });

    setShops((prev) =>
      prev.map((shop) =>
        shop.id === shopId ? { ...shop, [name]: value } : shop
      )
    );
  };

  const handleAddNewShop = async () => {
    setIsCreatingNewShop(true);
    try {
      const newShop = await dispatch(
        createShop({
          name: "New Shop",
          addressLine1: "",
          addressLine2: "",
          email: "",
          phone: "",
          longitude: 0,
          latitude: 0,
        }) as any
      ).unwrap();
      setShops((prev) => [...prev, newShop]);

      setLocationStates((prev) => ({
        ...prev,
        [newShop.id]: {
          addressInputText: "",
          locationSuggestions: [],
          initialValues: {
            email: "",
            phone: "",
            name: "",
            address: "",
            latitude: 0,
            longitude: 0,
          },
          isDirty: false,
        },
      }));
      setIsCreatingNewShop(false);
      toggleShop(newShop.id);
    } catch (error) {
      console.log(error);
      toast.error("Failed to create new location");
      setIsCreatingNewShop(false);
    }
  };

  const onSubmit = async (shopId: number, event: React.FormEvent) => {
    event.preventDefault();
    const locationState = locationStates[shopId];
    const phone = locationState.initialValues.phone;
    const { email, name } = getFormValues(event.target as HTMLFormElement);
    const {
      name: address,
      latitude,
      longitude,
    } = locationState.selectedLocation || {};
    const finalAddress = address || locationState.addressInputText;
    const addressLine1 = finalAddress.split(",")[0];
    const addressLine2 = finalAddress.split(",").slice(1).join(",").trim();
    let payload = {
      id: shopId,
      email,
      phone,
      name,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      latitude: latitude || locationState.initialValues.latitude,
      longitude: longitude || 0,
    };

    const invalidKeys = getInvalidKeys(payload);

    if (invalidKeys.length) {
      toast.error(`Please enter a ${invalidKeys[0]}`);
    } else if (phone.length < 11) {
      toast.error("Phone number doesn't seem to be right");
    } else {
      try {
        const newShop = await dispatch(updateShop(payload) as any).unwrap();
        setLocationStates((prev) => ({
          ...prev,
          [shopId]: {
            ...prev[shopId],
            initialValues: {
              ...newShop,
            },
            isDirty: false,
          },
        }));
        toast.success(`Location: ${name} updated successfully`);
      } catch (error) {
        toast.error(`Failed to update location: ${name}`);
        console.log(error);
      }
    }
  };

  const validShops = shops.filter((shop) => shop && locationStates[shop.id]);

  const toggleShop = (shopId: number) => {
    setOpenShops((prev) => ({ ...prev, [shopId]: !prev[shopId] }));
  };

  useEffect(() => {
    const initialLocationStates = existingShops.reduce((acc, shop) => {
      const address =
        shop.addressLine1 &&
        shop.addressLine2 &&
        `${shop.addressLine1}, ${shop.addressLine2 || ""}`;
      acc[shop.id] = {
        addressInputText: address || "",
        locationSuggestions: [],
        initialValues: {
          address: address || "",
          email: shop.email || "",
          phone: shop.phone || "",
          name: shop.name || "",
          latitude: shop.latitude,
          longitude: shop.longitude,
        },
        isDirty: false,
      };
      return acc;
    }, {} as Record<number, LocationState>);

    setLocationStates(initialLocationStates);
    setShops(existingShops);
  }, [existingShops]);

  return {
    validShops,
    locationStates,
    onLocationInputChange,
    onLocationSelect,
    onFormChange,
    handleAddNewShop,
    onSubmit,
    isCreatingNewShop,
    openShops,
    toggleShop,
    onShopArchiveToggle,
  };
};
