import {
  AvailabilityTypes,
  AvailabilityStatusTypes,
  updateWeeklyAvailability,
  updateAvailabilityOverride,
  AvailabilityableTypes,
  createAvailabilityOverride,
} from "app/redux/availabilitiesSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { merge } from "lodash";
import { toast } from "react-toastify";

export const useEditScreen = ({
  availability,
  availabilityEditingIntent,
  onOpenChange,
}: {
  availability?: any;
  availabilityEditingIntent: AvailabilityTypes;
  onOpenChange(isOpen: boolean): void;
}) => {
  const dispatch = useDispatch();
  const [scheduled, setScheduled] = useState<boolean>(
    availability?.status === AvailabilityStatusTypes.SCHEDULED
  );
  const [error, setError] = useState<string | null>(null);

  const handleAvailabilitySubmit = async (availabilityChanges: any) => {
    const updatedAvailability = {
      ...availability,
      ...availabilityChanges,
      // type: availabilityEditingIntent,
      // date: availability?.date,
      // day: availability?.day.toLowerCase(),
    };

    try {
      if (
        availabilityEditingIntent === AvailabilityTypes.WEEKLY_AVAILABILITY &&
        updatedAvailability.type === AvailabilityTypes.WEEKLY_AVAILABILITY
      ) {
        await dispatch(
          updateWeeklyAvailability({
            employeeId: availability?.availabilityableId,
            availability: merge(updatedAvailability, {
              date: availability?.date,
              day: availability?.day.toLowerCase(),
            }),
          }) as any
        ).unwrap();
      } else if (
        availabilityEditingIntent === AvailabilityTypes.AVAILABILITY_OVERRIDE &&
        updatedAvailability.type === AvailabilityTypes.AVAILABILITY_OVERRIDE
      ) {
        await dispatch(
          updateAvailabilityOverride({
            employeeId: availability?.availabilityableId,
            availability: updatedAvailability,
          }) as any
        ).unwrap();
      } else if (
        availabilityEditingIntent === AvailabilityTypes.WEEKLY_AVAILABILITY &&
        availability?.type === AvailabilityTypes.AVAILABILITY_OVERRIDE
      ) {
        await dispatch(
          updateAvailabilityOverride({
            employeeId: availability?.availabilityableId,
            availability: updatedAvailability,
            shouldUpdateWeekly: true,
          }) as any
        ).unwrap();
      } else if (
        availabilityEditingIntent === AvailabilityTypes.AVAILABILITY_OVERRIDE &&
        availability?.type === AvailabilityTypes.WEEKLY_AVAILABILITY
      ) {
        await dispatch(
          createAvailabilityOverride({
            employeeId: availability?.availabilityableId,
            availability: updatedAvailability,
          }) as any
        ).unwrap();
      }
      // await dispatch(
      //   getAvailabilitiesWithOverrideByEmployeeId({
      //     employeeId: availability?.availabilityableId,
      //     date: availability?.date,
      //   }) as any
      // ).unwrap();
      onOpenChange(false);
      toast.success("Availability updated successfully");
    } catch (err) {
      console.error("Error handling availability submission:", err);
      setError(
        "An error occurred while submitting availability?. Please try again."
      );
    }
  };

  const onScheduledSwitchToggle = async (checked: boolean) => {
    setScheduled(checked);
    try {
      await handleAvailabilitySubmit({
        ...availability,
        availabilityableType: AvailabilityableTypes.USER,
        status: checked
          ? AvailabilityStatusTypes.SCHEDULED
          : AvailabilityStatusTypes.UNSCHEDULED,
      });
    } catch (err) {
      console.error("Error handling availability submission:", err);
      setError(
        "An error occurred while updating the schedule. Please try again."
      );
    }
  };

  return {
    onScheduledSwitchToggle,
    handleAvailabilitySubmit,
    scheduled,
    error,
  };
};
