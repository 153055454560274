import * as React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { AvailabilityStatusTypes } from "app/redux/availabilitiesSlice";

export interface CustomEmployeeOptionProps {
  innerRef: any;
  innerProps: any;
  data: any;
}

export const CustomEmployeeOption: React.FC<CustomEmployeeOptionProps> = ({
  innerRef,
  innerProps,
  data,
}) => {
  // Check if the employee offers the specific business service and if it is active
  const isServiceActive = data.services.some(
    (service: any) =>
      service.businessService.id === data.businessServiceId &&
      service.status === "active"
  );

  const isAvailable = data?.selectedDate
    ? data.availabilities?.find(
        (availability: any) =>
          availability.date === format(data.selectedDate, "yyyy-MM-dd")
      )?.status === AvailabilityStatusTypes.SCHEDULED
    : true;

  const disabled = !isServiceActive || !isAvailable;

  // Apply custom classes based on the service active status
  const optionClass = classNames(
    "flex items-center gap-2 p-2 px-4 cursor-pointer",
    {
      "bg-gray-200 cursor-not-allowed text-gray-500": disabled, // Grayed out style for inactive
      "hover:bg-blue-200": !disabled, // Normal hover for active
    }
  );

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={optionClass}
      // Prevent click on employees who do not have this service active
      onClick={disabled ? (e) => e.preventDefault() : innerProps.onClick}
    >
      <div className="flex justify-between w-full gap-1">
        <span className="font-bold text-sm text-zinc-700">{data.label}</span>
      </div>
    </div>
  );
};
