import { Button, Input, Spinner } from "@nextui-org/react";
import { Path } from "app/path";
import * as React from "react";
import { Link } from "react-router-dom";
import { useSignup } from "./useSignup";

export interface SignupProps {}

export const Signup: React.FC<SignupProps> = ({}) => {
  const { loading, handleOnSignupSubmit } = useSignup();
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <div className="flex flex-col w-full max-w-lg gap-8 border rounded p-10 shadow-lg">
        <h1 className="text-2xl text-center">Welcome to All Set</h1>
        <form onSubmit={handleOnSignupSubmit} className="flex flex-col gap-4 ">
          <Input
            label="Email"
            labelPlacement="outside"
            validationBehavior={"native"}
            isRequired
            name="email"
            type="email"
            classNames={{
              label: "font-semibold",
              inputWrapper: "custom-input",
            }}
          />
          <Input
            labelPlacement="outside"
            validationBehavior={"native"}
            isRequired
            label="Password"
            name="password"
            classNames={{
              label: "font-semibold",
              inputWrapper: "custom-input",
            }}
          />
          <Input
            labelPlacement="outside"
            validationBehavior={"native"}
            isRequired
            label="Password Confirmation"
            name="passwordConfirmation"
            classNames={{
              label: "font-semibold",
              inputWrapper: "custom-input",
            }}
          />

          <Button
            color="primary"
            isDisabled={loading}
            type="submit"
            className="mt-4 bg-black rounded"
          >
            {loading && <Spinner size="sm" color="default" />} Sign up
          </Button>
          <Button
            as={Link}
            to={Path.SIGNIN}
            variant="light"
            className="rounded hover:!bg-black hover:text-white"
          >
            Already have an account, sign in here
          </Button>
        </form>
      </div>
    </div>
  );
};
