const styles = {
  container: `
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 16px;
    padding: 16px;
    border: 1px solid #e4e4e7;
    border-radius: 4px;
    background-color: #f9fafb;
    height: 100px;
  `,
  card: `
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    padding: 10px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 95%; /* Ensure the card field takes up the full width */
  `,
  expiryContainer: `
    display: flex;
    gap: 16px;
    width: 24%;
    gap: 8px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  `,
  expiryMonth: `
    // flex: 1; /* Flex property allows them to grow/shrink equally */
    padding: 10px;
  `,
  expirySeparator: `
    align-content: center;
    text-color: #6b7280;
  `,
  expiryYear: `
    // flex: 1;
    padding: 10px;
  `,
  cvv2: `
    flex: 1; /* Align CVV on the same line */
    padding: 10px 20px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 39px;
    right: 18px;
  `,
  avsZip: `
    padding: 10px 12px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  `,
  labels: `
    font-size: 14px;
    color: #6b7280;
    margin-bottom: 4px;
  `,
};

export default styles;
