import { format, parse, parseISO } from "date-fns";

export const format24TimeTo12Hour = (timeString: string) => {
  // Parse the time string to a Date object
  try {
    const time = parse(timeString, "HH:mm", new Date());
    return format(time, "h:mm a");
  } catch (error) {
    const time = parseISO(timeString);
    if (time.toString() !== "Invalid Date") {
      return format(time, "h:mm a");
    }
    console.error("Error parsing time string", error);
  }

  // Format the Date object to a 12-hour time string with AM/PM
};

export const adjustDateToUTC = (dateValue: string) => {
  const parsedDate = new Date(`${dateValue}T00:00:00Z`);
  return new Date(
    parsedDate.getTime() + parsedDate.getTimezoneOffset() * 60000
  );
};
