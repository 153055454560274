import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export enum OnboardingStep {
  BUSINESS_INFO = "business_info",
  HOW_MANY_SERVICE_PROVIDERS = "how_many_service_providers",
  PAYMENT_API_KEYS = "payment_api_keys",
  COMPLETE = "complete",
}

export interface OnboardingSliceState {
  id: number;
  accountId: number;
  step: OnboardingStep;
  createdAt: string;
  updatedAt: string;
}

const initialState = {} as OnboardingSliceState;

export const getOnboarding = createAsyncThunk(
  "onboarding/getOnboardingStatus",
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get("/v1/onboardings");
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const createOnboarding = createAsyncThunk(
// );

export const updateOnboarding = createAsyncThunk(
  "onboarding/updateOnboardingStatus",
  async (onboarding: Partial<OnboardingSliceState>, thunkAPI) => {
    try {
      const { data } = await http.patch("/v1/onboardings", onboarding);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const deleteOnboarding = createAsyncThunk(
// );

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOnboarding.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    builder.addCase(updateOnboarding.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
  },
});

export const selectOnboarding = (state: RootState) => state.onboarding;
