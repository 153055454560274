import { Avatar } from "@nextui-org/react";
import * as React from "react";
import { useState } from "react";
import { ResourceHeaderProps } from "react-big-calendar";

export const CustomResourceHeader: React.FC<any> = ({ resource }) => {
  return (
    <div
      onScroll={(e) => console.log(e)}
      className="flex items-center justify-between bg-white -translate-x-1 p-2 w-full border-b border-zinc-100 absolute h-[60px] z-10"
    >
      <div
        key={resource.resourceId}
        className="flex justify-center items-center w-full mx-2"
      >
        <Avatar
          src={
            resource.avatar ||
            "https://i.pravatar.cc/150?u=" + resource.resourceId
          }
          alt={resource.resourceTitle}
          name={resource.resourceTitle}
          className="w-8 h-8 rounded"
        />
        <p className="ml-2 text-sm font-semibold">{resource.resourceTitle}</p>
      </div>
    </div>
  );
};
