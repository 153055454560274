import {
  createBusinessService,
  createBusinessServiceCategory,
} from "app/redux/businessServicesSlice";
import { getEmployees } from "app/redux/employeesSlice";
import { getFormValues } from "app/utils/getFormValues";
import * as React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const useAddCategoryModal = ({
  setShowAddCategoryModal,
}: {
  setShowAddCategoryModal: (show: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = React.useState(false);

  const onFormChange = (event: any) => {
    const { category } = getFormValues(event.target.form);
    setIsDirty(category !== "");
  };

  const onSubmit = async (event: any) => {
    try {
      event.preventDefault();
      const { category } = getFormValues(event.target);
      const serviceCategory = await dispatch(
        createBusinessServiceCategory({ name: category }) as any
      ).unwrap();
      await dispatch(
        createBusinessService({
          title: "New Service",
          description: "Service description",
          duration: 30,
          priceCents: 2000,
          serviceCategoryId: serviceCategory.id,
        }) as any
      ).unwrap();
      await dispatch(getEmployees() as any).unwrap();
      toast.success(`Service Category ${category} created successfully`);
      setShowAddCategoryModal(false);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    onFormChange,
    isDirty,
    onSubmit,
  };
};
