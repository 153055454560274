import { Button, Divider, Input, Spinner } from "@nextui-org/react";
import { SalesSliceState } from "app/redux/salesSlice";
import * as React from "react";
import {
  PaymentTypes,
  testCreditCards,
  tipOptions,
  usePaymentCheckout,
} from "./usePaymentCheckout";
import { round } from "lodash";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FadeInSlide } from "app/motions/fadeInSlide";

export interface PaymentCheckoutProps {
  sale: any;
}

export const PaymentCheckout: React.FC<PaymentCheckoutProps> = ({ sale }) => {
  const {
    tipsCents,
    setTipsCents,
    total,
    amountToCharge,
    setAmountToCharge,
    onSubmit,
    showPaymentIframe,
    onCreditCardPayment,
    processingCreditCardCharge,
    toOpenInfoScreen,
    openCreditCardIframe,
    showCreditCardOptions,
    resetPaymentMethod,
    onPrepaidRecording,
  } = usePaymentCheckout({ sale });

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col content-center relative gap-2 ">
        <Button
          className="absolute left-0 w-4 h-4 top-1.5 hover:!bg-transparent"
          isIconOnly
          variant="light"
          onClick={() => toOpenInfoScreen()}
        >
          <ChevronLeftIcon />
        </Button>
        <p className="font-semibold text-center">Payments</p>
      </div>
      <Divider className="mt-4 mb-6" />
      <div className="flex flex-col gap-6 relative h-[90vh]">
        <div className="flex items-center justify-between">
          <p>Subtotal</p>
          <p className="font-semibold">{sale?.subtotal}</p>
        </div>
        <div className="flex items-center justify-between">
          <p>Tax</p>
          <p className="font-semibold">{sale?.tax}</p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex gap-5">
            <p>Tip</p>
            <div className="flex gap-6 items-center justify-center">
              {tipOptions.map((option) => (
                <Button
                  variant="light"
                  className="p-0 min-h-0 h-auto text-zinc-700 rounded-sm hover:!bg-transparent hover:underline"
                  key={option.label}
                  onClick={() =>
                    setTipsCents(sale?.subtotalCents * option.value)
                  }
                >
                  {option.label}
                </Button>
              ))}
            </div>
          </div>
          {/* <p className="font-semibold">${round(tipCents / 100, 2)}</p> */}
          <Input
            type="number"
            value={round(tipsCents / 100, 2) as any}
            startContent="$"
            endContent=""
            onChange={(e: any) => setTipsCents(e.target.value * 100)}
            classNames={{
              input: "font-semibold text-end",
              inputWrapper: "custom-input",
              base: "w-[100px]",
            }}
            variant="bordered"
          />
        </div>
        <div className="flex items-center justify-between">
          <p>Total</p>
          <p className="font-semibold">${total}</p>
        </div>
        <Divider />
        <div className="flex items-center justify-between my-3">
          <p>Amount to charge</p>
          <Input
            type="number"
            value={amountToCharge as any}
            startContent="$"
            endContent=""
            onChange={(e: any) => setAmountToCharge(e.target.value)}
            classNames={{
              input: "font-semibold text-end",
              inputWrapper: "custom-input",
              base: "w-[100px]",
            }}
            variant="bordered"
          />
        </div>
        <Divider />
        <div className="flex flex-col gap-4 justify-between">
          <div className="flex justify-between items-center">
            <p>
              {showPaymentIframe
                ? "Enter credit card information"
                : showCreditCardOptions
                ? "Pay now or record a payment"
                : "Pay with"}
            </p>
            {(showPaymentIframe || showCreditCardOptions) && (
              <Button
                variant="light"
                onClick={() => resetPaymentMethod()}
                className="min-h-0 h-auto hover:!bg-transparent hover:underline"
              >
                Undo
              </Button>
            )}
          </div>
          {!showCreditCardOptions && (
            <div className="flex gap-4">
              <Button
                className="bg-black text-white font-semibold p-3 rounded-none w-full"
                onClick={() => onSubmit(PaymentTypes.CREDIT_CARD)}
              >
                Credit Card
              </Button>
              <Button
                className="bg-black text-white font-semibold p-3 rounded-none w-full"
                onClick={() => onSubmit(PaymentTypes.CASH)}
              >
                Cash
              </Button>
            </div>
          )}
        </div>

        {showCreditCardOptions && (
          <div className="flex gap-4">
            <Button
              className="bg-black text-white font-semibold p-3 rounded-none w-full"
              onClick={() => openCreditCardIframe()}
            >
              Pay Now
            </Button>
            <Button
              className="bg-black text-white font-semibold p-3 rounded-none w-full"
              onClick={() => onPrepaidRecording(PaymentTypes.CREDIT_CARD)}
            >
              Already paid
            </Button>
          </div>
        )}

        <form
          onSubmit={onCreditCardPayment}
          className={classNames("flex flex-col h-full gap-4", {
            flex: showPaymentIframe,
            hidden: !showPaymentIframe,
          })}
        >
          <FadeInSlide direction="top">
            <div className="flex flex-col gap-2">
              <div id="accept-blue-iframe" />
            </div>
            <Button
              isDisabled={processingCreditCardCharge}
              type="submit"
              className="bg-black text-white p-4 rounded-sm float-end"
            >
              {processingCreditCardCharge && (
                <Spinner size="sm" color="default" />
              )}
              Process payment
            </Button>
          </FadeInSlide>
        </form>
      </div>
    </div>
  );
};
