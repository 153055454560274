import * as React from "react";
import {
  CheckoutScreenTypes,
  perPageList,
  sortByList,
  useSales,
} from "./useSales";
import { Drawer } from "app/components/drawer/drawer";
import { SalesTable } from "./components/salesTable";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import {
  CheckIcon,
  ChevronDownIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import classNames from "classnames";
import { titleize } from "app/utils/string";
import { OpenSaleScreen } from "./components/openSaleScreen/openSaleScreen";
import { ClosedSaleScreen } from "./components/closedSaleScreen/closedSaleScreen";
import { SaleStatusTypes } from "app/redux/salesSlice";
import { includes } from "lodash";
import { Loading } from "app/components/loading/loading";

export interface SalesProps {}

export const Sales: React.FC<SalesProps> = () => {
  const {
    shouldShowDrawer,
    setShouldShowDrawer,
    rowsPerPage,
    onSaleRowClick,
    sortBy,
    setSortBy,
    setRowsPerPage,
    saleIdParam,
    statusParam,
    screenParam,
    handleClientSearch,
    onOpenNewCheckoutDrawer,
    onResetDrawerScreens,
    loading,
  } = useSales();

  if (loading) {
    return (
      <div className="absolute top-1/3 left-1/2">
        <Loading />
      </div>
    );
  }

  const screenToShow =
    saleIdParam && statusParam === SaleStatusTypes.OPEN ? (
      <OpenSaleScreen handleClientSearch={handleClientSearch} />
    ) : saleIdParam &&
      includes([SaleStatusTypes.CLOSED, SaleStatusTypes.VOID], statusParam) ? (
      <ClosedSaleScreen setShouldShowDrawer={setShouldShowDrawer} />
    ) : !saleIdParam && !statusParam ? (
      <OpenSaleScreen handleClientSearch={handleClientSearch} />
    ) : null;

  return (
    <div className="flex flex-col py-4 pr-0 mt-2 gap-6">
      <Drawer
        isOpen={shouldShowDrawer}
        setIsOpen={setShouldShowDrawer}
        backdrop={false}
        hideCloseBtn={screenParam === CheckoutScreenTypes.CLOSED}
        // closeOnBackdropClick={false}
      >
        {screenToShow}
      </Drawer>
      <div className="flex items-center justify-between">
        <div className="flex gap-2 items-center ml-3">
          <Button
            variant="ghost"
            className="flex gap-2 rounded w-[208px] border-black mr-2"
            onClick={() => onOpenNewCheckoutDrawer()}
          >
            <PlusIcon className="w-4 h-4 stroke-black" />
            <p className="font-semibold text-black">New Checkout</p>
          </Button>
        </div>
        <div className="flex">
          <Dropdown>
            <DropdownTrigger>
              <Button variant="light" className="flex items-center">
                <p className="font-semibold">Sort by</p>
                <ChevronDownIcon className="w-3 h-3 stroke-black" />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              {sortByList.map((sb) => (
                <DropdownItem
                  key={sb}
                  onClick={() => setSortBy(sb)}
                  endContent={
                    <CheckIcon
                      className={classNames("w-3 h-3 stroke-black", {
                        hidden: sb !== sortBy,
                      })}
                    />
                  }
                >
                  {titleize(sb)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          <Dropdown>
            <DropdownTrigger>
              <Button variant="light" className="flex items-center">
                <p className="font-semibold">{rowsPerPage} per page</p>
                <ChevronDownIcon className="w-3 h-3 stroke-black" />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              {perPageList.map((perPage) => (
                <DropdownItem
                  key={perPage}
                  onClick={() => setRowsPerPage(perPage)}
                >
                  {perPage} per page
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <SalesTable rowsPerPage={rowsPerPage} onSaleRowClick={onSaleRowClick} />
    </div>
  );
};
