import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export enum SaleStatusTypes {
  OPEN = "open",
  CLOSED = "closed",
  VOID = "void",
}

export interface SalesSliceState {
  id: number;
  status: SaleStatusTypes;
  subtotal: string;
  subtotalCents: number;
  client: any;
  created_by: any;
  tax: string;
  tips: string;
  tipsCents: number;
  taxCents: number;
  paidOnPlatform: boolean;
  totalChargedAmountCents: number;
  totalChargedAmount: string;
  paymentMethod: string;
  salesMerchantServices: any[];
  createdAt: string;
  updatedAt: string;
}

interface SalesSliceReduxState {
  sales: SalesSliceState[];
  meta: {
    totalPages: number;
    currentPage: number;
    perPage: number;
    totalCount: number;
  };
}

const initialState = {
  sales: [],
  meta: {
    totalPages: 0,
    currentPage: 0,
    perPage: 0,
    totalCount: 0,
  },
} as SalesSliceReduxState;

export const getSales = createAsyncThunk(
  "sales/getSales",
  async (
    {
      page = 1,
      perPage = 30,
      sortBy = "created_at",
    }: {
      page?: number;
      perPage?: number;
      sortBy?: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.get(
        `/v1/sales?page=${page}&per_page=${perPage}&sort_by=${sortBy}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createSale = createAsyncThunk(
  "sales/createSale",
  async (
    {
      subtotalCents,
      taxCents,
      tipsCents,
      totalChargedAmountCents,
      paymentMethod,
      clientId,
      createdById,
      merchantServiceIds,
    }: {
      subtotalCents: number;
      taxCents?: number;
      tipsCents?: number;
      totalChargedAmountCents?: number;
      paymentMethod?: string;
      clientId: number;
      createdById: number;
      merchantServiceIds: number[];
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post("/v1/sales", {
        sale: {
          subtotalCents,
          taxCents,
          tipsCents,
          totalChargedAmountCents,
          paymentMethod,
          clientId,
          createdById,
          merchantServiceIds,
          // notesAttributes: [{ content: note }],
        },
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSale = createAsyncThunk(
  "sales/getSale",
  async (saleId: number, thunkAPI) => {
    try {
      const { data } = await http.get(`/v1/sales/${saleId}`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateSale = createAsyncThunk(
  "sales/updateSale",
  async (sale: any, thunkAPI) => {
    try {
      const { data } = await http.patch(`/v1/sales/${sale.id}`, {
        sale,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const payWithCreditCard = createAsyncThunk(
  "sales/payWithCreditCard",
  async (
    { source, amount, name, expiryMonth, expiryYear, tip }: any,
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/sales/pay_with_credit_card`, {
        source,
        amount,
        name,
        expiryMonth,
        expiryYear,
        tip,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const voidSale = createAsyncThunk(
  "sales/voidSale",
  async (saleId: number, thunkAPI) => {
    try {
      const { data } = await http.post(`/v1/sales/${saleId}/void`, {});
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const testApiKeys = createAsyncThunk(
  "sales/testApiKeys",
  async (
    {
      acceptBlueTokenizationApi,
      acceptBlueApiKey,
      acceptBlueApiPin,
    }: {
      acceptBlueTokenizationApi?: string;
      acceptBlueApiKey: string;
      acceptBlueApiPin: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/sales/test_charge`, {
        acceptBlueTokenizationApi,
        acceptBlueApiKey,
        acceptBlueApiPin,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSales.fulfilled, (state, action) => {
      const { sales: newSales, meta } = action.payload;
      // Directly set the new sales and meta information
      state.sales = newSales;
      state.meta = meta;
    });

    builder.addCase(createSale.fulfilled, (state, action) => {
      state.sales.unshift(action.payload);
    });
    builder.addCase(updateSale.fulfilled, (state, action) => {
      const index = state.sales.findIndex(
        (sale) => sale.id === action.payload.id
      );
      state.sales[index] = action.payload;
    });
    builder.addCase(voidSale.fulfilled, (state, action) => {
      const index = state.sales.findIndex(
        (sale) => sale.id === action.payload.id
      );
      state.sales[index] = action.payload;
    });
  },
});

export const selectSales = (state: RootState) => state.sales;
