import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "app/requests/axiosInstance";

export const searchLocation = createAsyncThunk(
  "mapbox/searchLocation",
  async ({ query }: { query: string }, thunkAPI) => {
    try {
      const { data } = await http.post(`/v1/mapbox/search_location`, {
        query,
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
