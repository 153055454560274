import { useDispatch, useSelector } from "react-redux";
import { selectAccount, updateAccount } from "app/redux/accountSlice";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";

export const useBranding = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);

  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [landingImagesPreviews, setLandingImagesPreviews] = useState<string[]>(
    []
  );

  // Initialize the previews based on account data
  useEffect(() => {
    if (account?.logo) {
      setLogoPreview(account.logo);
    }
    if (account?.landingImages?.length > 0) {
      const initialPreviews = account.landingImages.map(
        (image: string) => image
      );
      setLandingImagesPreviews(initialPreviews);
    }
  }, [account]);

  const handleLogoChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const logoUrl = URL.createObjectURL(file);
      setLogoPreview(logoUrl);

      try {
        await dispatch(updateAccount({ logo: file }) as any).unwrap();
        toast.success("Logo updated successfully");
      } catch (error: any) {
        console.error(
          "Error updating logo:",
          error.response || error.message || error
        );
        toast.error("Failed to update logo");
      }
    }
  };

  const handleLandingImagesChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);

      // Create previews for the new images
      const previews = newFiles.map((file) => URL.createObjectURL(file));
      setLandingImagesPreviews((prev) => [...prev, ...previews]);

      try {
        // Send only the new files to the backend
        await dispatch(
          updateAccount({ landingImages: newFiles }) as any
        ).unwrap();
        toast.success("Landing images updated successfully");
      } catch (error: any) {
        console.error(
          "Error updating landing images:",
          error.response || error.message || error
        );
        toast.error("Failed to update landing images");
      }
    }
  };

  const saveSelectedImage = async (link: string) => {
    try {
      await dispatch(
        updateAccount({
          selectedLandingImageLink: link,
        }) as any
      ).unwrap();
      toast.success("Selected image saved successfully");
    } catch (error: any) {
      console.error(
        "Error saving selected image:",
        error.response || error.message || error
      );
      toast.error("Failed to save selected image");
    }
  };

  useEffect(() => {
    return () => {
      // Revoke object URLs to avoid memory leaks
      if (logoPreview) URL.revokeObjectURL(logoPreview);
      landingImagesPreviews.forEach((preview) => URL.revokeObjectURL(preview));
    };
  }, [logoPreview, landingImagesPreviews]);

  return {
    logoPreview,
    landingImagesPreviews,
    handleLogoChange,
    handleLandingImagesChange,
    saveSelectedImage,
    account,
  };
};
