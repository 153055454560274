import * as React from "react";
import {
  Table,
  Pagination,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
} from "@nextui-org/react";
import { useSalesTable } from "./useSalesTable";
import { format } from "date-fns";
import classNames from "classnames";
import { SaleStatusTypes } from "app/redux/salesSlice";
import { titleize } from "app/utils/string";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

export interface SalesTableProps {
  rowsPerPage: number;
  onSaleRowClick: (saleId: number) => void;
}

export const SalesTable: React.FC<SalesTableProps> = ({
  rowsPerPage,
  onSaleRowClick,
}) => {
  const {
    totalCount,
    page,
    handlePageChange,
    sortDescriptor,
    setSortDescriptor,
    sortedItems,
  } = useSalesTable({
    rowsPerPage,
  });

  const renderCell = React.useCallback((sale: any, columnKey: any) => {
    const cellValue = sale[columnKey];
    switch (columnKey) {
      case "id":
        return (
          <div className="flex flex-col">
            <p className="text-gray-800 text-sm">{cellValue}</p>
          </div>
        );
      case "status":
        return (
          <div className="flex w-full items-center gap-2.5">
            <div
              className={classNames("h-2 w-2 rounded-full", {
                "bg-[#F9C97C]": cellValue === SaleStatusTypes.OPEN,
                "bg-[#D9D9D9]": cellValue === SaleStatusTypes.CLOSED,
                "bg-[#c9a9e9]": cellValue === SaleStatusTypes.VOID,
              })}
            />
            <p className="text-gray-800 text-sm">{titleize(cellValue)}</p>
          </div>
        );
      case "createdAt":
        return (
          <div className="flex flex-col">
            <p className="text-gray-800 text-sm">
              {format(cellValue, "MMM dd yyyy")}
            </p>
          </div>
        );
      case "client":
        return (
          <div className="flex items-center gap-2">
            <Avatar
              src={cellValue.avatar}
              name={cellValue.name}
              className="rounded w-6 h-8"
            />
            <p className="text-gray-800 text-sm">{cellValue.name}</p>
          </div>
        );
      case "totalChargedAmount":
        return (
          <div className="flex items-center gap-2">
            <p className="text-gray-800 text-sm">
              {sale.totalChargedAmountCents === 0 ? sale.subtotal : cellValue}
            </p>
          </div>
        );
      case "paymentMethod":
        return (
          <div className="flex items-center gap-2">
            <p className="text-gray-800 text-sm">
              {sale.status === SaleStatusTypes.OPEN ? "" : titleize(cellValue)}
            </p>
          </div>
        );
      case "platformPaidOn":
        return (
          <div className="flex items-center">
            {sale.paidOnPlatform && (
              <CheckCircleIcon className="w-5 h-5 stroke-success-500" />
            )}
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  const pages = Math.ceil(totalCount / rowsPerPage);

  return (
    <Table
      isStriped
      bottomContent={
        <div className="flex w-full justify-center">
          <Pagination
            isCompact
            showControls={pages > 1}
            variant="light"
            color="default"
            classNames={{
              item: "transparent",
            }}
            page={page}
            total={pages}
            onChange={(page) => handlePageChange(page)}
          />
        </div>
      }
      isHeaderSticky
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
      classNames={{
        base: "h-[88vh]",
        wrapper: "min-h-[222px] border-none shadow-none p-0",
        thead: "bg-white shadow-none border-none !rounded-none",
        th: "bg-transparent font-normal",
        tr: "h-12 cursor-pointer hover:bg-[#D1F4E0]",
      }}
    >
      <TableHeader>
        <TableColumn allowsSorting key="id">
          #
        </TableColumn>
        <TableColumn allowsSorting key="status">
          Status
        </TableColumn>
        <TableColumn allowsSorting key="createdAt">
          Date
        </TableColumn>
        <TableColumn allowsSorting key="client">
          Client
        </TableColumn>
        <TableColumn allowsSorting key="totalChargedAmount">
          Total
        </TableColumn>
        <TableColumn allowsSorting key="paymentMethod">
          Payment
        </TableColumn>
        <TableColumn allowsSorting key="platformPaidOn">
          Paid on platform
        </TableColumn>
      </TableHeader>
      <TableBody items={sortedItems}>
        {(item: any) => (
          <TableRow key={item.id} onClick={() => onSaleRowClick(item)}>
            {(columnKey) => (
              <TableCell className="text-black">
                {renderCell(item, columnKey)}
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
