import { OtherEventTypes } from "app/schedules/useSchedules";
import { useQuery } from "app/utils/useQuery";
import { find } from "lodash";
import * as React from "react";

export const useCustomEvent = ({ title, shop }: any) => {
  const query = useQuery();
  const shopId = query.get("shopId");
  const isOffTime = title === OtherEventTypes.OFF;
  const isCurrentShop = Number(shopId) === shop?.id;
  return {
    isOffTime,
    isCurrentShop,
  };
};
