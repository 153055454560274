import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState, useEffect } from "react";
import { selectBusinessServices } from "app/redux/businessServicesSlice";
import { EmployeesSliceState } from "app/redux/employeesSlice";
import { toast } from "react-toastify";
import { updateMerchantService } from "app/redux/merchantServicesSlice";

enum ServiceStatusStates {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export const useStaffServices = ({
  selectedEmployee,
}: {
  selectedEmployee: EmployeesSliceState;
}) => {
  const dispatch = useDispatch();
  const businessServices = useSelector(selectBusinessServices);
  const employeeServices = selectedEmployee.services;
  const [durations, setDurations] = useState<{ [key: number]: number }>({});
  const [prices, setPrices] = useState<{ [key: number]: string }>({});
  const [activeStatus, setActiveStatus] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [dirtyServices, setDirtyServices] = useState<{
    [key: number]: boolean;
  }>({});
  const [updatingServices, setUpdatingServices] = useState<{
    [key: number]: boolean;
  }>({});

  const employeeServicesMap = useMemo(() => {
    const map: { [key: number]: any } = {};
    employeeServices.forEach((service) => {
      map[service.id] = service; // Use employeeService.id as the key
    });
    return map;
  }, [employeeServices]);

  const combinedCategories = useMemo(() => {
    return businessServices.map((category) => ({
      ...category,
      services: category.services.map((service: any) => {
        const employeeService = employeeServices.find(
          (empService) => empService.businessService.id === service.id
        );

        return {
          ...service,
          id: employeeService ? employeeService.id : service.id, // Use employeeService ID if it exists
          price: employeeService ? employeeService.price : service.price,
          priceCents: employeeService
            ? employeeService.priceCents
            : service.priceCents,
          duration: employeeService
            ? employeeService.duration
            : service.duration,
          active: employeeService
            ? employeeService.status === ServiceStatusStates.ACTIVE
            : false,
        };
      }),
    }));
  }, [businessServices, employeeServices]);

  const handleActiveToggle = async (serviceId: number, checked: boolean) => {
    const service = employeeServicesMap[serviceId]; // Now this maps correctly
    if (!service) {
      console.error(`Service with id ${serviceId} not found`);
      return;
    }

    const payload = {
      ...service,
      status: checked
        ? ServiceStatusStates.ACTIVE
        : ServiceStatusStates.INACTIVE,
    };

    try {
      setUpdatingServices((prev) => ({ ...prev, [serviceId]: true }));
      await dispatch(updateMerchantService(payload) as any).unwrap();
      toast.success("Service updated successfully");
      setActiveStatus((prev) => ({
        ...prev,
        [serviceId]: checked,
      }));
      setUpdatingServices((prev) => ({ ...prev, [serviceId]: false }));
    } catch (error: any) {
      setUpdatingServices((prev) => ({ ...prev, [serviceId]: false }));
      console.error(error);
      toast.error("Failed to update service");
    }
  };

  const updateService = async (serviceId: number) => {
    const service = employeeServicesMap[serviceId];
    if (!service) {
      console.error(`Service with id ${serviceId} not found`);
      return;
    }

    const payload = {
      ...service,
      id: serviceId,
      duration: durations[serviceId],
      priceCents: Number(prices[serviceId]) * 100,
    };

    try {
      setUpdatingServices((prev) => ({ ...prev, [serviceId]: true }));
      await dispatch(updateMerchantService(payload) as any).unwrap();
      toast.success("Service updated successfully");

      // Update local state to reflect the changes
      setDurations((prev) => ({
        ...prev,
        [serviceId]: payload.duration,
      }));
      setPrices((prev) => ({
        ...prev,
        [serviceId]: String(payload.priceCents / 100),
      }));
      setDirtyServices((prev) => ({ ...prev, [serviceId]: false })); // Mark as not dirty after updating
      setUpdatingServices((prev) => ({ ...prev, [serviceId]: false }));
    } catch (error: any) {
      setUpdatingServices((prev) => ({ ...prev, [serviceId]: false }));
      console.error(error);
      toast.error("Failed to update service");
    }
  };

  const syncServices = async () => {
    const initialDurations: { [key: number]: number } = {};
    const initialPrices: { [key: number]: string } = {};
    const initialActiveStatus: { [key: number]: boolean } = {};

    combinedCategories
      .flatMap((category) => category.services)
      .forEach((service) => {
        initialDurations[service.id] = service.duration;
        initialPrices[service.id] = String(service.priceCents / 100);
        initialActiveStatus[service.id] = service.active;
      });

    setDurations(initialDurations);
    setPrices(initialPrices);
    setActiveStatus(initialActiveStatus);
    setDirtyServices({}); // Reset dirty status when syncing services
  };

  useEffect(() => {
    syncServices();
  }, [combinedCategories]);

  return {
    combinedCategories,
    durations,
    prices,
    activeStatus,
    dirtyServices,
    updatingServices,
    setDuration: (data: { id: number; duration: number; label?: string }) => {
      setDurations((prev) => ({
        ...prev,
        [data.id]: data.duration,
      }));
      setDirtyServices((prev) => ({
        ...prev,
        [data.id]: true, // Mark as dirty when modified
      }));
    },
    setPrice: (data: { id: number; price: string }) => {
      setPrices((prev) => ({
        ...prev,
        [data.id]: data.price,
      }));
      setDirtyServices((prev) => ({
        ...prev,
        [data.id]: true, // Mark as dirty when modified
      }));
    },
    setActiveStatus: (data: { id: number; active: boolean }) => {
      setActiveStatus((prev) => ({
        ...prev,
        [data.id]: data.active,
      }));
      setDirtyServices((prev) => ({
        ...prev,
        [data.id]: true, // Mark as dirty when modified
      }));
    },
    updateService,
    handleActiveToggle,
  };
};
