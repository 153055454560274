import {
  EmployeesSliceState,
  selectEmployees,
  updateEmployee,
} from "app/redux/employeesSlice";
import { selectShops } from "app/redux/shopsSlice";
import { useQuery } from "app/utils/useQuery";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export const useStaffDetails = ({
  selectedEmployee,
}: {
  selectedEmployee: EmployeesSliceState;
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const employees = useSelector(selectEmployees);
  const existingShops = useSelector(selectShops);
  const [employeeShops, setEmployeeShops] = useState<any[]>([]);

  const onEmployeeUpdate = async (data: any) => {
    try {
      const newEmployee = await dispatch(
        updateEmployee({
          ...data,
          id: selectedEmployee.id,
        }) as any
      ).unwrap();
      toast.success(`${newEmployee.name} was updated successfully`);
    } catch (error) {
      toast.error("Failed to update employee");
      console.log(error);
    }
  };

  const handleShopSelectionChange = (selectedOptions: any) => {
    if (selectedEmployee) {
      const selectedShopIds = selectedOptions.map(
        (option: any) => option.value
      );

      const updatedEmployee = {
        id: selectedEmployee.id,
        shopIds: selectedShopIds,
      };

      // Update the employee and notify
      onEmployeeUpdate(updatedEmployee);
    }
  };

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("employee[avatar]", file);
      formData.append("employee[id]", selectedEmployee.id.toString());

      try {
        await dispatch(
          updateEmployee({
            id: selectedEmployee.id.toString(),
            avatar: formData,
          }) as any
        ).unwrap();
        toast.success("Avatar updated successfully");
      } catch (error) {
        console.log("error", error);
        toast.error("Failed to update avatar");
      }
    }
  };

  useEffect(() => {
    const defaultShopSelectValue =
      selectedEmployee?.shops?.map((shop: any) => ({
        value: shop.id,
        label: shop.name,
      })) || [];

    setEmployeeShops(defaultShopSelectValue);
  }, [selectedEmployee]);

  return {
    employees,
    selectedEmployee,
    onEmployeeUpdate,
    existingShops,
    handleShopSelectionChange,
    employeeShops,
    handleAvatarChange,
  };
};
