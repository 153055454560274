import { select } from "@nextui-org/react";
import { Path } from "app/path";
import {
  createAppointment,
  deleteAppointment,
  deleteAppointmentGroup,
  updateAppointment,
} from "app/redux/appointmentsSlice";
import { selectBusinessServices } from "app/redux/businessServicesSlice";
import { searchClients, selectClients } from "app/redux/clientsSlice";
import { selectEmployees } from "app/redux/employeesSlice";
import { selectSchedules } from "app/redux/schedulesSlice";
import { selectShops } from "app/redux/shopsSlice";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import { getFormValues } from "app/utils/getFormValues";
import { useQuery } from "app/utils/useQuery";
import {
  addMinutes,
  differenceInHours,
  differenceInMinutes,
  format,
  parse,
  startOfDay,
} from "date-fns";
import { debounce, find, sortBy, uniqBy } from "lodash";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useReadAppointmentScreen = ({
  selectedAppointmentEvent,
  getSchedulesOfEmployees,
  toggleAppointmentDrawer,
  setPendingAppointment,
}: {
  selectedAppointmentEvent: any;
  getSchedulesOfEmployees: ({ currentDate }: { currentDate?: Date }) => void;
  toggleAppointmentDrawer: (open: boolean) => void;
  setPendingAppointment: (appointment: any) => void;
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const currentDate = query.get("date") as string;
  const appointmentId = query.get("appointmentId") as string;
  const navigate = useNavigate();
  const employees = useSelector(selectEmployees);
  const employee = selectedAppointmentEvent.employee;
  const businessServices = useSelector(selectBusinessServices);
  const { clients } = useSelector(selectClients);
  const [isEditing, setIsEditing] = useState(false);
  const schedules = useSelector(selectSchedules);
  const [editingEmployeeInServiceIndex, setEditingEmployeeInServiceIndex] =
    useState<number | null>(null);
  const [isDateInputFocused, setIsDateInputFocused] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    selectedAppointmentEvent.start
  );
  const [originalSelectedServices, setOriginalSelectedServices] = useState<
    any[]
  >([]);
  const employeeSelectRef = useRef<any>(null);
  const [availableServices, setAvailableServices] = useState(businessServices);
  const [selectedClient, setSelectedClient] = useState<any>(
    selectedAppointmentEvent.client
  );
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const [serviceSelectValue, setServiceSelectValue] = useState<any>(null);
  // const [existingServices, setExistingServices] = useState<any[]>([]);

  const onHandleClientChange = (selectedOption: any) => {
    setSelectedClient({
      ...selectedOption,
      id: selectedOption.value,
      name: selectedOption.label,
    });
  };

  const getSpecificAppointmentGroup = (
    appointmentId: string,
    specificDate?: string
  ) => {
    const currentSchedule = specificDate
      ? schedules[specificDate]
      : schedules[currentDate] || {};

    // Extract all appointment groups from each employee's schedule
    const allAppointmentGroups = Object.values(currentSchedule)
      .flatMap((schedule: any) => schedule.appointmentGroups)
      .filter((group: any) => group); // Filter out any undefined or null values

    // Ensure the appointment groups are unique by their ID
    const uniqueAppointmentGroups = uniqBy(allAppointmentGroups, "id");

    const appointmentsGroupToShow = find(
      uniqueAppointmentGroups,
      (group: any) =>
        group.appointments.some(
          (appointment: any) => appointment.id === Number(appointmentId)
        )
    );

    const sortedAppointmentsByStartTime = sortBy(
      appointmentsGroupToShow?.appointments,
      (appointment: any) => {
        return new Date(appointment.startTime).getTime();
      }
    );

    return {
      ...appointmentsGroupToShow,
      appointments: sortedAppointmentsByStartTime,
    };
  };

  const handleClientSearch = async (inputValue: string, callback: any) => {
    if (inputValue.length > 2) {
      try {
        const response = await dispatch(
          searchClients(inputValue) as any
        ).unwrap();
        const results =
          response.clients?.map((c: any) => ({
            value: c.id,
            label: c.name,
            phone: c.phone,
            email: c.email,
            createdAt: c.createdAt,
            avatar: c.avatar,
          })) || [];
        callback(results);
      } catch (error) {
        console.error("Error searching clients:", error);
      }
    } else {
      return [];
    }
  };

  const updateSelectedServicesWithStartTimes = ({
    appointmentId,
    setSelectedServices,
  }: {
    appointmentId: string;
    setSelectedServices: React.Dispatch<React.SetStateAction<any[]>>;
  }) => {
    const appointmentsGroupToShow = getSpecificAppointmentGroup(appointmentId);

    if (appointmentsGroupToShow) {
      const servicesWithStartTimes =
        appointmentsGroupToShow.appointments.flatMap((appointment: any) => {
          // Parse the original startTime considering UTC offset
          let currentStartTime = preserveUtcTimeToLocal(appointment.startTime);

          return appointment.services.map((service: any) => {
            // Directly format the start time for display
            const startAtTime = format(currentStartTime, "hh:mm a");
            // Update currentStartTime for the next service
            currentStartTime = addMinutes(currentStartTime, service.duration);

            return {
              ...service,
              startAtTime,
            };
          });
        });

      setSelectedServices(servicesWithStartTimes);
      setOriginalSelectedServices(servicesWithStartTimes);
    }
  };

  const onRemoveService = (index: number) => {
    const updatedServices = [...selectedServices];
    updatedServices.splice(index, 1); // Remove the service at the specified index

    // Recalculate start times for the remaining services
    let lastServiceEndTime: Date | undefined;

    const servicesWithUpdatedTimes = updatedServices.map((service, idx) => {
      if (idx === 0) {
        // If it's the first service, set the start time based on the appointment start time
        lastServiceEndTime = preserveUtcTimeToLocal(
          getSpecificAppointmentGroup(selectedAppointmentEvent.appointment.id)
            .appointments[0].startTime
        );
      }

      const serviceStartTime = lastServiceEndTime
        ? lastServiceEndTime
        : new Date();

      // Calculate the next service's end time
      lastServiceEndTime = addMinutes(serviceStartTime, service.duration);

      // Update the service with the new start time
      return {
        ...service,
        startAtTime: format(serviceStartTime, "hh:mm a"),
      };
    });

    setSelectedServices(servicesWithUpdatedTimes);
  };

  const onHandleAddService = (selectedOption: any) => {
    const employeeService = find(
      employee!.services,
      (service) => service.businessService.id === selectedOption.value
    );

    if (!employeeService) {
      console.error("Employee service not found");
      return;
    }

    // Calculate the start time for the new service
    let lastServiceEndTime: Date | undefined;

    if (selectedServices.length > 0) {
      const lastService = selectedServices[selectedServices.length - 1];

      lastServiceEndTime = addMinutes(
        parse(lastService.startAtTime, "hh:mm a", new Date()),
        lastService.duration
      );
    } else {
      // If no services exist, use the start time of the appointment
      try {
        lastServiceEndTime = preserveUtcTimeToLocal(
          getSpecificAppointmentGroup(selectedAppointmentEvent.appointment.id)
            .appointments[0].startTime
        );
      } catch (error) {
        console.error("Error getting appointment start time:", error);
        lastServiceEndTime = new Date(); // Fallback to current date/time
      }
    }

    const newServiceStartTime = lastServiceEndTime;

    const newServiceWithTime = {
      ...employeeService,
      startAtTime: format(newServiceStartTime, "hh:mm a"), // Format the start time
    };

    // Add the new service with the start time to the selected services list
    setSelectedServices([...selectedServices, newServiceWithTime]);

    const updatedAvailableServices = availableServices.map((category) => ({
      ...category,
      services: category.services.filter(
        (service: any) => service.id !== selectedOption.value
      ),
    }));

    setAvailableServices(updatedAvailableServices);
    setServiceSelectValue(null); // Clear the Select component
  };

  const handleEmployeeChange = (serviceIndex: number, selectedOption: any) => {
    const updatedServices = [...selectedServices];
    const newEmployee = employees.find(
      (emp) => emp.id === selectedOption.value
    );
    const newEmployeeService = newEmployee?.services.find(
      (service) =>
        service.businessService.id ===
        updatedServices[serviceIndex].businessService.id
    );
    updatedServices[serviceIndex] = {
      ...updatedServices[serviceIndex],
      employee: newEmployee,
      id: newEmployeeService.id,
      price: newEmployeeService.price,
      priceCents: newEmployeeService.priceCents,
      status: newEmployeeService.status,
      duration: newEmployeeService.duration,
    };
    setSelectedServices(updatedServices);
    setTimeout(() => {
      setEditingEmployeeInServiceIndex(null);
    }, 0);
  };

  // Assume these imports are correct based on your project structure

  const onUpdateAppointment = async (event: any) => {
    event.preventDefault();
    try {
      if (selectedServices.length === 0) {
        return toast.error("Please select at least one service");
      }
      // Extract form values
      const { startTime } = getFormValues(event.target);

      // Format and parse dates
      const formattedDate = format(selectedDate, "yyyy-MM-dd");
      const parsedDate = parse(formattedDate, "yyyy-MM-dd", new Date());
      const parsedTime = parse(startTime, "HH:mm", parsedDate);
      let currentStartDateTime = new Date(
        parsedDate.getFullYear(),
        parsedDate.getMonth(),
        parsedDate.getDate(),
        parsedTime.getHours(),
        parsedTime.getMinutes()
      );

      const appointmentsGroupToShow = getSpecificAppointmentGroup(
        selectedAppointmentEvent.appointment.id,
        format(selectedAppointmentEvent.start, "yyyy-MM-dd")
      );

      // Extract all appointment groups from each employee's schedule
      const existingAppointments = appointmentsGroupToShow.appointments;

      // Prepare lists to track operations
      const updatePromises = [];
      const createPromises = [];
      const deletePromises: any = [];

      let appointmentGroupId = appointmentsGroupToShow.id;

      // Handle updating existing appointments and creating new ones
      for (const service of selectedServices) {
        const existingAppointment = existingAppointments.find((appt: any) =>
          appt.services.some(
            (existingService: any) => existingService.id === service.id
          )
        );

        // Calculate the end time by adding service duration to currentStartDateTime
        const endDateTime = addMinutes(currentStartDateTime, service.duration);

        const payload = {
          startTime: format(currentStartDateTime, "yyyy-MM-dd HH:mm:ss"),
          endTime: format(endDateTime, "yyyy-MM-dd HH:mm:ss"),
          clientId: selectedClient.id,
          shopId: Number(query.get("shopId")),
          email: selectedClient.email,
          name: selectedClient.name,
          employeeId: service.employee.id,
          serviceIds: [service.id],
          appointmentGroupId: appointmentGroupId,
          tipsCents: 0, // Until tips are implemented
        } as any;

        if (existingAppointment) {
          // Update existing appointment
          updatePromises.push(
            dispatch(
              updateAppointment({
                ...payload,
                appointmentId: existingAppointment.id,
              }) as any
            ).unwrap()
          );
        } else {
          // Create new appointment
          createPromises.push(
            dispatch(createAppointment(payload) as any).unwrap()
          );
        }

        // Update start time for next service
        currentStartDateTime = endDateTime;
      }

      // Handle deleting appointments where all services are removed
      const serviceIdsInSelectedServices = selectedServices.map(
        (service) => service.id
      );
      const appointmentsToDelete = existingAppointments.filter(
        (appt: any) =>
          !appt.services.some((existingService: any) =>
            serviceIdsInSelectedServices.includes(existingService.id)
          )
      );

      appointmentsToDelete.forEach((appt: any) => {
        deletePromises.push(
          dispatch(deleteAppointment(appt.id) as any).unwrap()
        );
      });

      // Execute all promises
      await Promise.all([
        ...updatePromises,
        ...createPromises,
        ...deletePromises,
      ]);

      getSchedulesOfEmployees({
        currentDate: startOfDay(selectedDate),
      });
      setPendingAppointment(null);
      setIsEditing(false);

      toast.success("Appointment updated successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update appointment. Please try again.");
    }
  };

  const onDeleteAppointmentGroup = async () => {
    const shouldDeleteAppointmentGroup = window.confirm(
      "Are you sure you want to delete this appointment?"
    );
    if (!shouldDeleteAppointmentGroup) {
      return;
    }
    try {
      await dispatch(
        deleteAppointmentGroup(
          selectedAppointmentEvent.appointment.appointmentGroup.id
        ) as any
      ).unwrap();
      query.delete("appointmentId");
      navigate(`${Path.HOME}?${query.toString()}`);
      toggleAppointmentDrawer(false);
      await getSchedulesOfEmployees({
        currentDate: startOfDay(selectedDate),
      });
      toast.success("Appointment deleted successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete appointment. Please try again.");
    }
  };

  const cancelEditing = () => {
    setSelectedServices(originalSelectedServices);
    setIsEditing(false);
    setPendingAppointment(null);
  };

  useEffect(() => {
    return () => {
      query.delete("appointmentId");
      navigate(`${Path.HOME}?${query.toString()}`);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        employeeSelectRef.current &&
        !employeeSelectRef.current.contains(event.target as Node)
      ) {
        setEditingEmployeeInServiceIndex(null);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [employeeSelectRef]);

  useEffect(() => {
    if (selectedAppointmentEvent) {
      updateSelectedServicesWithStartTimes({
        appointmentId,
        setSelectedServices,
      });
    }
  }, [selectedAppointmentEvent]);

  useEffect(() => {
    if (employee && businessServices.length > 0) {
      const updatedAvailableServices = businessServices.map((category) => ({
        ...category,
        services: category.services.filter(
          (service: any) =>
            // Filter out services that are already selected
            !selectedServices.some(
              (selectedService) =>
                selectedService.businessService.id === service.id
            )
        ),
      }));
      setAvailableServices(updatedAvailableServices);
    }
  }, [employee, businessServices, selectedServices]);

  const onDateChange = async (dateValue: any) => {
    if (dateValue === selectedAppointmentEvent.start) {
      return;
    }

    await getSchedulesOfEmployees({
      currentDate: startOfDay(dateValue),
    });

    const appointmentGroup = getSpecificAppointmentGroup(
      selectedAppointmentEvent.appointment.id,
      format(selectedAppointmentEvent.start, "yyyy-MM-dd")
    );

    const startTimeElement = document.querySelector("#startTime") as any;
    const startTime = startTimeElement.value || "";
    const formattedStartDate = format(dateValue, "yyyy-MM-dd");
    const endTime = addMinutes(
      new Date(`${formattedStartDate}T${startTime}`),
      differenceInMinutes(
        appointmentGroup.appointments[appointmentGroup.appointments.length - 1]
          .endTime,
        appointmentGroup.appointments[0].startTime
      )
    );

    query.delete("date");
    query.append("date", format(dateValue, "yyyy-MM-dd"));
    navigate(`${Path.HOME}?${query.toString()}`);

    setPendingAppointment({
      ...selectedAppointmentEvent,
      pending: true,
      title: `Edit ${selectedClient.name}'s appointment`,
      start: new Date(`${formattedStartDate}T${startTime}`),
      end: new Date(endTime),
    });
  };

  // useEffect(() => {
  //   onDateChange({ target: { value: format(selectedDate, "yyyy-MM-dd") } });
  // }, [selectedDate]);

  const onTimeChange = (event: any) => {
    const appointmentGroup = getSpecificAppointmentGroup(
      selectedAppointmentEvent.appointment.id,
      format(selectedAppointmentEvent.start, "yyyy-MM-dd")
    );
    const formattedStartDate = format(selectedDate, "yyyy-MM-dd");
    const formattedStartDateTime = `${formattedStartDate}T${event.target.value}`;
    const endTime = addMinutes(
      new Date(formattedStartDateTime),
      differenceInMinutes(
        appointmentGroup.appointments[appointmentGroup.appointments.length - 1]
          .endTime,
        appointmentGroup.appointments[0].startTime
      )
    );
    setPendingAppointment({
      ...selectedAppointmentEvent,
      title: `Edit ${selectedClient.name}'s appointment`,
      start: new Date(formattedStartDateTime),
      pending: true,
      end: new Date(endTime),
    });
  };

  return {
    isEditing,
    setIsEditing,
    onUpdateAppointment,
    selectedClient,
    setSelectedClient,
    onHandleClientChange,
    clients,
    selectedServices,
    setSelectedServices,
    editingEmployeeInServiceIndex,
    setEditingEmployeeInServiceIndex,
    employeeSelectRef,
    employees,
    handleEmployeeChange,
    serviceSelectValue,
    onHandleAddService,
    availableServices,
    selectedDate,
    setSelectedDate,
    isDateInputFocused,
    setIsDateInputFocused,
    onRemoveService,
    getSpecificAppointmentGroup,
    cancelEditing,
    onDeleteAppointmentGroup,
    handleClientSearch,
    onTimeChange,
    onDateChange,
  };
};
