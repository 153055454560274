import { Button, Input, Spinner } from "@nextui-org/react";
import * as React from "react";
import { useBusinessInfo } from "./useBusinessInfo";

export interface BusinessInfoProps {
  step: number;
  totalSteps: number;
  setCurrentStep: (step: string) => void;
}

export const BusinessInfo: React.FC<BusinessInfoProps> = ({
  step,
  totalSteps,
  setCurrentStep,
}) => {
  const { onSubmit, loading } = useBusinessInfo({
    setCurrentStep,
  });

  return (
    <div className="flex flex-col mt-[6%] items-center m-auto w-full h-screen max-w-[500px]">
      <div className="bg-[#353535] h-6 w-6 rounded-full mb-16" />
      <div className="flex flex-col gap-4 items-center mb-12">
        <p className="text-zinc-500 text-sm">
          Step {step} of {totalSteps}
        </p>
        <h1 className="text-zinc-700 text-2xl font-semibold">
          What's the name of your business?
        </h1>
      </div>
      <form onSubmit={onSubmit} className="w-full flex flex-col gap-10">
        <Input
          label="Business Name"
          name="businessName"
          labelPlacement="outside"
          placeholder="Enter your business name"
          isRequired
          validationBehavior="native"
          classNames={{
            label: "font-semibold !text-zinc-700",
            inputWrapper: "custom-input",
          }}
        />
        <Input
          label="Website"
          labelPlacement="outside"
          name="website"
          placeholder="Enter your website"
          validationBehavior="native"
          classNames={{
            label: "font-semibold !text-zinc-700",
            inputWrapper: "custom-input",
          }}
        />
        <Button className="w-full rounded text-white bg-black" type="submit">
          {loading && <Spinner size="sm" color="default" />}
          Continue
        </Button>
      </form>
    </div>
  );
};
