import { Path } from "app/path";
import { searchClients } from "app/redux/clientsSlice";
import { getSales } from "app/redux/salesSlice";
import { delay } from "app/utils/delay";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const perPageList = [30, 60, 90];
export const sortByList = ["date", "status"];
export enum CheckoutScreenTypes {
  PAYMENT = "payment",
  CLOSED = "closed",
  VOID = "void",
}

export const useSales = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const saleIdParam = query.get("saleId");
  const statusParam = query.get("status");
  const screenParam = query.get("screen");
  const pageParam = query.get("page");
  const [rowsPerPage, setRowsPerPage] = useState(perPageList[0]);
  const [sortBy, setSortBy] = useState(sortByList[0]);
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchSales = async () => {
    let modifiedSortBy = sortBy;
    if (sortBy === "date") {
      modifiedSortBy = "created_at";
    }

    setLoading(true);
    try {
      await dispatch(
        getSales({
          perPage: rowsPerPage,
          sortBy: modifiedSortBy,
          page: Number(pageParam) || 1,
        }) as any
      ).unwrap();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const onSaleRowClick = (sale?: any) => {
    setShouldShowDrawer(true);
    const saleStatus = sale?.status;
    navigate(
      `?saleId=${sale.id}&status=${sale.status}&screen=${
        saleStatus === "open"
          ? CheckoutScreenTypes.PAYMENT
          : CheckoutScreenTypes.CLOSED
      }`
    );
  };

  const handleClientSearch = async (inputValue: string, callback: any) => {
    if (inputValue.length > 2) {
      try {
        const response = await dispatch(
          searchClients(inputValue) as any
        ).unwrap();
        const results =
          response.clients?.map((c: any) => ({
            value: c.id,
            label: c.name,
            phone: c.phone,
            email: c.email,
            createdAt: c.createdAt,
            avatar: c.avatar,
          })) || [];
        callback(results);
      } catch (error) {
        console.error("Error searching clients:", error);
      }
    } else {
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      fetchSales();
    })();
  }, [rowsPerPage, sortBy, pageParam]);

  useEffect(() => {
    if (saleIdParam) {
      setShouldShowDrawer(true);
    }
  }, []);

  const onOpenNewCheckoutDrawer = () => {
    onResetDrawerScreens();
    if (!shouldShowDrawer) {
      setShouldShowDrawer(true);
    }
  };

  const onResetDrawerScreens = () => {
    query.delete("saleId");
    query.delete("status");
    query.delete("screen");
    navigate(`${Path.SALES}?${query.toString()}`);
  };

  return {
    shouldShowDrawer,
    setShouldShowDrawer,
    onSaleRowClick,
    rowsPerPage,
    setSortBy,
    sortBy,
    setRowsPerPage,
    saleIdParam,
    statusParam,
    screenParam,
    handleClientSearch,
    onOpenNewCheckoutDrawer,
    onResetDrawerScreens,
    loading,
  };
};
