import { UserAuth, UserContextType } from "app/context/AuthContext";
import * as React from "react";
import { useState } from "react";
import { toast } from "react-toastify";

export const useSecurity = () => {
  const { updateUserPassword, reauthenticateUser } =
    UserAuth() as UserContextType;
  const [formValues, setFormValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (formValues.newPassword !== formValues.confirmPassword) {
      return toast.error("Passwords do not match");
    }

    try {
      await reauthenticateUser(formValues.currentPassword);
      await updateUserPassword(formValues.newPassword);
      toast.success("Password updated successfully");
    } catch (e: any) {
      console.log(e);
      if (e.code === "auth/wrong-password") {
        return toast.error("Wrong password");
      }
    }
  };

  const onFormChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const allFieldsFilled = Object.values(formValues).every(
    (value) => value.trim() !== ""
  );

  return { onFormChange, allFieldsFilled, onSubmit };
};
