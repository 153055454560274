import { Path } from "app/path";
import { selectBusinessServices } from "app/redux/businessServicesSlice";
import { selectClients } from "app/redux/clientsSlice";
import { selectEmployees } from "app/redux/employeesSlice";
import { createSale, selectSales, updateSale } from "app/redux/salesSlice";
import { selectUser } from "app/redux/userSlice";
import { useQuery } from "app/utils/useQuery";
import { find, sumBy, round } from "lodash";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useNewCheckout = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const saleIdParam = query.get("saleId");
  const { sales } = useSelector(selectSales);
  const sale = find(sales, (sale: any) => sale.id === Number(saleIdParam));
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { clients } = useSelector(selectClients);
  const employees = useSelector(selectEmployees);
  const employee = employees[0];
  const businessServices = useSelector(selectBusinessServices);
  const [availableServices, setAvailableServices] = useState(businessServices);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedServices, setSelectedServices] = useState<any>([]);
  const [serviceSelectValue, setServiceSelectValue] = useState(null);
  const [selectedSale, setSelectedSale] = useState<any>(null);
  const [editingEmployeeInServiceIndex, setEditingEmployeeInServiceIndex] =
    useState<number | null>(null);
  const employeeSelectRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onHandleClientChange = (selectedOption: any) => {
    setSelectedClient({
      ...selectedOption,
      id: selectedOption.value,
      name: selectedOption.label,
    });
  };

  const handleEmployeeChange = (serviceIndex: number, selectedOption: any) => {
    const updatedServices = [...selectedServices];
    const newEmployee = employees.find(
      (emp) => emp.id === selectedOption.value
    );
    const newEmployeeService = newEmployee?.services.find(
      (service) =>
        service.businessService.id ===
        updatedServices[serviceIndex].businessService.id
    );
    updatedServices[serviceIndex] = {
      ...updatedServices[serviceIndex],
      employee: newEmployee,
      id: newEmployeeService.id,
      price: newEmployeeService.price,
      priceCents: newEmployeeService.priceCents,
      status: newEmployeeService.status,
      duration: newEmployeeService.duration,
    };
    setSelectedServices(updatedServices);
    setTimeout(() => {
      setEditingEmployeeInServiceIndex(null);
    }, 0);
  };

  const onHandleAddService = (selectedOption: any) => {
    const employeeService = find(
      employee!.services,
      (service) => service.businessService.id === selectedOption.value
    );

    setSelectedServices([...selectedServices, employeeService]);

    const updatedAvailableServices = availableServices.map((category) => ({
      ...category,
      services: category.services.filter(
        (service: any) => service.id !== selectedOption.value
      ),
    }));

    setAvailableServices(updatedAvailableServices);
    setServiceSelectValue(null); // Clear the Select component
  };

  const onRemoveService = (index: number) => {
    const updatedServices = [...selectedServices];
    updatedServices.splice(index, 1); // Remove the service at the specified index

    setSelectedServices(updatedServices);
  };

  const onSaveAndContinue = async (event: any) => {
    event.preventDefault();
    const payload = {
      id: saleIdParam || null,
      subtotalCents: sumBy(selectedServices, "priceCents"),
      clientId: selectedClient.id,
      merchantServiceIds: selectedServices.map((service: any) => service.id),
      createdById: user.id,
    };
    try {
      setIsLoading(true);
      const { id, status } = await dispatch(
        saleIdParam ? updateSale(payload) : (createSale(payload) as any)
      ).unwrap();
      query.append("saleId", id);
      query.append("status", status);
      query.append("screen", "payment");
      navigate(`${Path.SALES}?${query.toString()}`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getSubtotal = () => {
    return `$${round(sumBy(selectedServices, "priceCents") / 100, 2).toFixed(
      2
    )}`;
  };

  useEffect(() => {
    if (saleIdParam) {
      setSelectedSale(sale);
      setSelectedClient(sale?.client);

      setSelectedServices(
        sale?.salesMerchantServices.map((sms: any) => sms.merchantService)
      );
    } else {
      setSelectedClient(null);
      setSelectedServices([]);
    }
  }, [saleIdParam]);

  return {
    clients,
    selectedClient,
    setSelectedClient,
    onHandleClientChange,
    selectedServices,
    editingEmployeeInServiceIndex,
    setEditingEmployeeInServiceIndex,
    employeeSelectRef,
    employees,
    handleEmployeeChange,
    onHandleAddService,
    serviceSelectValue,
    availableServices,
    employee,
    onRemoveService,
    onSaveAndContinue,
    isLoading,
    sale,
    getSubtotal,
  };
};
