import * as React from "react";
import { useClosedSaleScreen } from "./useClosedSaleScreen";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Spinner,
} from "@nextui-org/react";
import { format } from "date-fns";
import { titleize } from "app/utils/string";
import {
  EllipsisHorizontalIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";

export interface ClosedSaleScreenProps {
  setShouldShowDrawer: (value: boolean) => void;
}

export const ClosedSaleScreen: React.FC<ClosedSaleScreenProps> = ({
  setShouldShowDrawer,
}) => {
  const { sale, loading, onVoidSale, loadingReason, onCloneSale } =
    useClosedSaleScreen();

  if (loading) {
    return (
      <Spinner
        label={loadingReason}
        size="lg"
        className="absolute left-1/2 top-1/3"
      />
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center gap-3 mt-3 mb-6 justify-between">
        <div className="flex gap-3">
          <p className="font-semibold">Sale #{sale.id}</p>
          <Chip
            color="default"
            className={classNames("w-11 rounded-sm text-sm", {
              "bg-[#e4d4f4]": sale.status === "void",
              "bg-[#c2cfd2]": sale.status === "closed",
            })}
          >
            {titleize(sale.status)}
          </Chip>
        </div>
        <div className="flex items-center gap-4">
          {sale.status !== "void" && sale.paidOnPlatform && (
            <Dropdown
              classNames={{
                content: "rounded-sm",
              }}
            >
              <DropdownTrigger>
                <Button
                  variant="light"
                  isIconOnly
                  className="rounded p-1 w-7 h-7"
                >
                  <EllipsisHorizontalIcon />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  variant="flat"
                  key="void"
                  className="rounded-sm"
                  color="default"
                  onClick={onVoidSale}
                >
                  Void
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          <Button
            variant="light"
            isIconOnly
            className="rounded p-1 w-6 h-6"
            onClick={() => setShouldShowDrawer(false)}
          >
            <XMarkIcon />
          </Button>
        </div>
      </div>
      {sale.status === "void" && (
        <div className="flex flex-col p-4 gap-1 border border-[#E4E4E7] bg-[#FAFAFA] rounded">
          <div className="flex items-center">
            <ExclamationTriangleIcon className="w-5 h-5 stroke-[#F5A524] mr-1" />
            <p className="font-semibold text-sm">
              You've voided this transaction
            </p>
          </div>
          <p className="text-sm mb-4">
            This transaction is cancelled, and the payment will not be <br />{" "}
            processed. If you want to create a new payment for this sale, <br />{" "}
            press the <span className="font-semibold">“Clone this sale”</span>{" "}
            button below.
          </p>
          <Button
            variant="bordered"
            className="bg-white text-black rounded-sm w-full border-black font-semibold p-[10px]"
            onClick={() => onCloneSale(sale)}
          >
            Clone this sale
          </Button>
        </div>
      )}
      <div className="flex items-center gap-5">
        <Avatar
          className="rounded w-14 h-14"
          name={sale.client.name}
          src={"https://i.pravatar.cc/150?u=" + sale.client.id}
        />
        <div className="flex flex-col gap-2 my-6">
          <p className="font-semibold">{sale.client.name}</p>
          <p className="text-zinc-500 text-sm">
            Client since {format(sale.client.createdAt, "M.dd.yyyy")}
          </p>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-6 pt-6">
        {sale.salesMerchantServices.map((service: any) => (
          <div className="flex items-center justify-between" key={service.id}>
            <div className="flex flex-col gap-2" key={service.id}>
              <p className="font-semibold">{service.title}</p>
              <div className="flex">
                <div className="flex gap-1 text-sm">
                  <span className="text-zinc-500 text-sm">with</span>
                  <div className="font-semibold text-zinc-900 cursor-pointer relative hover:underline">
                    {service?.employee?.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <p className="font-semibold">{service.price}</p>
            </div>
          </div>
        ))}
        <Divider />
      </div>
      <div className="flex flex-col py-6 gap-6">
        <div className="flex items-center justify-between">
          <p>Subtotal</p>
          <p className="font-semibold">{sale.subtotal}</p>
        </div>
        <div className="flex items-center justify-between">
          <p>Tax</p>
          <p className="font-semibold">{sale.tax}</p>
        </div>
        <div className="flex items-center justify-between">
          <p>Tip</p>
          <p className="font-semibold">{sale.tips}</p>
        </div>
        <div className="flex items-center justify-between">
          <p className="font-semibold">Total</p>
          <p className="font-semibold">{sale.totalChargedAmount}</p>
        </div>
        <Divider />
        <div className="flex items-center justify-between">
          <p className="font-semibold">{titleize(sale.paymentMethod)}</p>
          <p className="font-semibold">{sale.totalChargedAmount}</p>
        </div>
      </div>
    </div>
  );
};
