import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "app/requests/axiosInstance";
import { RootState } from "./store";

export interface AppointmentsSliceState {
  id: number;
  uid: string;
  serviceProvider: any;
  status: string;
  services: any[];
  appointmentGroup: any;
  startTime: string;
  endTime: string;
  createdAt: string;
  updatedAt: string;
}

// Define the initial state using that type
const initialState = [] as AppointmentsSliceState[];

export const createAppointment = createAsyncThunk(
  "appointments/createAppointmentStatus",
  async (
    {
      startTime,
      endTime,
      shopId,
      email,
      name,
      tipsCents,
      employeeId,
      serviceIds,
      appointmentGroupId,
    }: {
      startTime: string;
      endTime: string;
      shopId: number;
      email: string;
      name: string;
      tipsCents: number;
      employeeId: number;
      serviceIds: number[];
      userId?: number;
      appointmentGroupId?: number;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/appointments`, {
        appointment: {
          startTime,
          endTime,
          shopId,
          email,
          name,
          employeeId,
          serviceIds,
          tipsCents,
          appointmentGroupId,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateAppointment = createAsyncThunk(
  "appointments/updateAppointmentStatus",
  async (
    {
      appointmentId,
      startTime,
      endTime,
      clientId,
      shopId,
      tipsCents,
      employeeId,
      serviceIds,
    }: {
      appointmentId?: number;
      startTime?: any;
      endTime?: any;
      shopId?: number;
      clientId?: string;
      tipsCents?: number;
      employeeId?: number;
      serviceIds?: number[];
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.patch(`/v1/appointments/${appointmentId}`, {
        appointment: {
          startTime,
          endTime,
          shopId,
          employeeId,
          serviceIds,
          tipsCents,
          clientId,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteAppointment = createAsyncThunk(
  "appointments/deleteAppointmentStatus",
  async (appointmentId: number, thunkAPI) => {
    try {
      await http.delete(`/v1/appointments/${appointmentId}`);
      return appointmentId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteAppointmentGroup = createAsyncThunk(
  "appointments/deleteAppointmentGroupStatus",
  async (appointmentGroupId: number, thunkAPI) => {
    try {
      await http.delete(`/v1/appointment_groups/${appointmentGroupId}`);
      return appointmentGroupId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAppointment.fulfilled, (state, action) => {
      state.push(action.payload);
    });
    builder.addCase(updateAppointment.fulfilled, (state, action) => {
      const index = state.findIndex(
        (appointment) => appointment.id === action.payload.id
      );
      state[index] = action.payload;
    });
    builder.addCase(deleteAppointment.fulfilled, (state, action) => {
      return state.filter((appointment) => appointment.id !== action.payload);
    });
  },
});

export const selectAppointments = (state: RootState) => state.appointments;
