import { UserAuth, UserContextType } from "app/context/AuthContext";
import { Path } from "app/path";
import {
  completeUserInvite,
  validateUserInviteToken,
} from "app/redux/userSlice";
import { getFormValues } from "app/utils/getFormValues";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useAcceptInvite = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get("token");
  const navigate = useNavigate();
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<any>();
  const { emailPassSignUp } = UserAuth() as UserContextType;

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const { email, name, role, password, passwordConfirmation } = getFormValues(
      e.target
    );
    if (password !== passwordConfirmation) {
      return toast.error("Passwords do not match");
    }
    if (password.length < 8) {
      return toast.error("Password must be at least 8 characters long");
    }

    try {
      await emailPassSignUp(email, password);
      await dispatch(completeUserInvite({}) as any).unwrap();
      navigate(Path.HOME);
      toast.success("Sign up successful, Welcone to the team!");
    } catch (error) {
      console.log(error);
    }
  };

  const validateToken = async () => {
    setIsLoading(true);
    try {
      const user = await dispatch(
        validateUserInviteToken({ token }) as any
      ).unwrap();
      if (!user.invitePending) {
        setIsLoading(false);
        toast.error("Token is no longer valid, please contact the sender.");
        return;
      }
      setUser(user);
      setIsTokenValid(true);
      setIsLoading(false);
      toast.success("Token is valid, please continue to sign up.");
    } catch (error) {
      setIsTokenValid(false);
      toast.error(
        "Invalid token, please contact the sender for a new invite link."
      );
      console.log(error);
    }
  };

  useEffect(() => {
    if (!token) {
      setIsLoading(false);
      setIsTokenValid(false);
    }
    validateToken();
  }, [token]);

  return {
    isTokenValid,
    isLoading,
    user,
    onSubmit,
  };
};
