import { updateAccount } from "app/redux/accountSlice";
import { OnboardingStep, updateOnboarding } from "app/redux/onboardingSlice";
import { getFormValues } from "app/utils/getFormValues";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const useBusinessInfo = ({
  setCurrentStep,
}: {
  setCurrentStep: (step: string) => void;
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    const { businessName, website } = getFormValues(event.target);

    try {
      setLoading(true);
      await dispatch(
        updateAccount({ name: businessName, website }) as any
      ).unwrap();
      await dispatch(
        updateOnboarding({
          step: OnboardingStep.HOW_MANY_SERVICE_PROVIDERS,
        }) as any
      ).unwrap();
      setCurrentStep(OnboardingStep.HOW_MANY_SERVICE_PROVIDERS);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.data?.error?.length) {
        toast.error(`Business ${error?.data?.error?.[0]}`);
      }
      console.error(error);
    }
  };

  return {
    onSubmit,
    loading,
  };
};
