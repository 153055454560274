import { Path } from "app/path";
import {
  createSale,
  getSale,
  selectSales,
  voidSale,
} from "app/redux/salesSlice";
import { useQuery } from "app/utils/useQuery";
import { find } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useClosedSaleScreen = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navgitate = useNavigate();
  const { sales } = useSelector(selectSales);
  const saleParam = query.get("saleId");
  const [sale, setSale] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [loadingReason, setLoadingReason] = useState("");

  const onVoidSale = async () => {
    try {
      setLoading(true);
      setLoadingReason("Voiding sale");
      await dispatch(voidSale(Number(sale.id)) as any).unwrap();
      toast.success("Sale voided successfully");
      setLoading(false);
      setLoadingReason("");
    } catch (error: any) {
      setLoading(false);
      setLoadingReason("");
      if (error.error?.error_message) {
        return toast.error(error.error.error_message);
      } else {
        toast.error("Failed to void sale");
      }
      console.error("Failed to void sale");
    }
  };

  const onCloneSale = async (sale: any) => {
    try {
      setLoading(true);
      setLoadingReason("Cloning sale");
      const response = await dispatch(
        createSale({
          ...sale,
          status: "open",
          acceptBlueTransactionId: null,
          merchantServiceIds: sale.salesMerchantServices.map(
            (sms: any) => sms.merchantService.id
          ),
          clientId: sale.client.id,
          createdById: sale.createdBy.id,
        }) as any
      ).unwrap();
      query.delete("saleId");
      query.delete("status");
      query.delete("screen");
      query.set("saleId", response.id.toString());
      query.set("status", "open");
      navgitate(`${Path.SALES}?${query.toString()}`);
      toast.success("Sale cloned successfully");
      setLoading(false);
      setLoadingReason("");
    } catch (error) {
      console.error("Failed to clone sale:", error);
      setLoading(false);
      setLoadingReason("");
    }
  };

  useEffect(() => {
    const fetchSale = async () => {
      setLoading(true);
      let selectedSale = find(sales, (s) => s.id === Number(saleParam)) || null;

      if (!selectedSale) {
        try {
          const fetchedSale = await dispatch(
            getSale(Number(saleParam)) as any
          ).unwrap();
          selectedSale = fetchedSale;
        } catch (error) {
          console.error("Failed to fetch sale:", error);
        }
      }

      setSale(selectedSale);
      setLoading(false);
    };

    fetchSale();
  }, [saleParam, sales, dispatch]);

  return {
    sale,
    loading,
    onVoidSale,
    loadingReason,
    onCloneSale,
  };
};
