import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import http from "app/requests/axiosInstance";
import { getEmployees } from "./employeesSlice";

export enum UserRoles {
  EMPLOYEE = "employee",
  ADMIN = "admin",
}

export interface UserSliceState {
  id: number;
  name: string;
  email: string;
  account: any;
  archivedAt?: string;
  invitationAcceptedAt?: string;
  role: UserRoles;
  avatar: string;
  createdAt: string;
  updatedAt: string;
}

// Define the initial state using that type
const initialState = {} as UserSliceState;

export const getUser = createAsyncThunk(
  "user/getUserStatus",
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get("/users");
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userSignup = createAsyncThunk(
  "user/signupStatus",
  async ({ email, name, accountType, authProvider }: any, thunkAPI) => {
    try {
      const response = await http.post("/users", {
        user: {
          email,
          name,
          accountType,
          authProvider,
        },
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userUpdate = createAsyncThunk(
  "user/updateStatus",
  async (user: any, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type":
            user instanceof FormData
              ? "multipart/form-data"
              : "application/json",
        },
      };
      const { data } = await http.patch("/users", user, config);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const archiveUser = createAsyncThunk(
  "user/archiveUserStatus",
  async (_: any, thunkAPI) => {
    try {
      const { data } = await http.post(`/users/archive`, {});
      thunkAPI.dispatch(getEmployees());
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const unArchiveUser = createAsyncThunk(
  "user/unArchiveUserStatus",
  async ({ id }: any, thunkAPI) => {
    try {
      const { data } = await http.post(`/users/unarchive`, {
        user: {
          id,
        },
      });
      thunkAPI.dispatch(getEmployees());
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const inviteUser = createAsyncThunk(
  "user/inviteUserStatus",
  async (
    {
      email,
      name,
      role,
      phoneNumber,
    }: {
      email: string;
      name: string;
      role: string;
      phoneNumber?: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/invites`, {
        invite: {
          email,
          name,
          role,
          phoneNumber,
        },
      });
      thunkAPI.dispatch(getEmployees());
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resendUserInvite = createAsyncThunk(
  "user/resendUserInviteStatus",
  async ({ id }: any, thunkAPI) => {
    try {
      const { data } = await http.post(`/invites/reinvite`, {
        invite: {
          userId: id,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const validateUserInviteToken = createAsyncThunk(
  "user/validateUserInviteTokenStatus",
  async ({ token }: any, thunkAPI) => {
    try {
      const { data } = await http.post(`/invites/validate`, {
        token,
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// complete_registration_invites POST   /invites/complete_registration(.:format)

export const completeUserInvite = createAsyncThunk(
  "user/completeUserInviteStatus",
  async (_: any, thunkAPI) => {
    try {
      const { data } = await http.post(`/invites/complete_registration`, {});
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(userSignup.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
    builder.addCase(userUpdate.fulfilled, (state: any, action) => {
      return Object.assign({}, state, action.payload);
    });
  },
});

export const selectUser = (state: RootState) => state.user;
