import { TrashIcon } from "@heroicons/react/24/outline";
import { Avatar, Button, Card, Spinner } from "@nextui-org/react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { CustomClientOption } from "app/schedules/components/crudAppointmentScreens/components/customClientOption";
import { CustomEmployeeOption } from "app/schedules/components/crudAppointmentScreens/components/customEmployeeOption";
import { CustomServiceOption } from "app/schedules/components/crudAppointmentScreens/components/customServiceOption";
import { round, sumBy } from "lodash";
import { format } from "date-fns";
import * as React from "react";
import { useNewCheckout } from "./useNewCheckout";

export interface NewCheckoutProps {
  handleClientSearch: (inputValue: string, callback: any) => void;
}

export const NewCheckout: React.FC<NewCheckoutProps> = ({
  handleClientSearch,
}) => {
  const {
    onSaveAndContinue,
    selectedClient,
    setSelectedClient,
    clients,
    onHandleClientChange,
    selectedServices,
    setEditingEmployeeInServiceIndex,
    editingEmployeeInServiceIndex,
    employeeSelectRef,
    handleEmployeeChange,
    employees,
    onRemoveService,
    onHandleAddService,
    availableServices,
    employee,
    serviceSelectValue,
    isLoading,
    sale,
  } = useNewCheckout();
  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col gap-2 mt-1.5 mb-7">
        <p className="font-semibold">
          {sale?.id ? `Sale #${sale.id}` : "New Checkout"}
        </p>
      </div>
      <form
        className="flex flex-col relative h-[90vh]"
        onSubmit={onSaveAndContinue}
      >
        <div className="flex flex-col gap-6">
          {selectedClient ? (
            <div className="flex flex-col relative">
              <Button
                isIconOnly
                variant="light"
                onClick={() => setSelectedClient(null)}
                className="rounded absolute top-0 right-0"
              >
                <TrashIcon className="w-4 h-4 text-zinc-500" />
              </Button>
              <div className="flex pb-4">
                <Avatar
                  src={selectedClient.avatar}
                  name={selectedClient.name}
                  className="rounded w-14 h-14"
                />
                <div className="flex flex-col justify-between ml-4">
                  <p className="font-semibold pt-0.5">{selectedClient.name}</p>
                  <p className="text-zinc-500 text-sm">
                    Client since {format(selectedClient.createdAt, "M.d.yyyy")}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-4">
                  <p className="text-zinc-500 w-[56px] text-sm">Phone:</p>
                  <p className="font-semibold ">
                    {selectedClient.phone || "(###) ###-####"}
                  </p>
                </div>
                <div className="flex items-center gap-4">
                  <p className="text-zinc-500 w-[56px] text-sm">Email:</p>
                  <p className="font-semibold ">
                    {selectedClient.email || "###"}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <AsyncSelect
              cacheOptions
              loadOptions={handleClientSearch as any}
              onChange={onHandleClientChange}
              className="text-zinc-500 text-sm"
              placeholder="Search or select a client"
              components={{
                Option: CustomClientOption,
              }}
              options={
                clients.map((c: any) => ({
                  value: c.id,
                  label: c.name,
                  phone: c.phone,
                  email: c.email,
                  createdAt: c.createdAt,
                  avatar: "https://i.pravatar.cc/150?u=" + c.id,
                })) as any
              }
              defaultOptions={clients.map((c: any) => ({
                value: c.id,
                label: c.name,
                phone: c.phone,
                email: c.email,
                createdAt: c.createdAt,
                avatar: "https://i.pravatar.cc/150?u=" + c.id,
              }))}
            />
          )}
          {selectedServices &&
            selectedServices.map((service: any, index: any) => (
              <div className="flex items-center justify-between" key={index}>
                <div className="flex flex-col gap-2">
                  <p className="font-semibold">{service.title}</p>
                  <div className="flex gap-4">
                    <div className="flex gap-1 text-sm">
                      <span className="text-zinc-500 text-sm">with</span>
                      <div
                        className="font-semibold text-zinc-900 cursor-pointer relative hover:underline"
                        onClick={() => setEditingEmployeeInServiceIndex(index)}
                      >
                        {editingEmployeeInServiceIndex === index ? (
                          <div
                            ref={employeeSelectRef}
                            className="absolute -top-2 w-[200px]"
                          >
                            <Select
                              placeholder="Select an employee"
                              components={{
                                Option: CustomEmployeeOption,
                              }}
                              onChange={(selectedOption) =>
                                handleEmployeeChange(index, selectedOption)
                              }
                              className="text-zinc-500 text-sm"
                              options={employees.map((emp: any) => ({
                                value: emp.id,
                                label: emp.name,
                                services: emp.services,
                                availabilities: emp.availabilities,
                                businessServiceId:
                                  selectedServices[index]?.businessService.id, // Pass the specific business service ID
                              }))}
                              onBlur={() =>
                                setEditingEmployeeInServiceIndex(null)
                              }
                              value={
                                selectedServices[index]?.employee
                                  ? {
                                      value:
                                        selectedServices[index]?.employee?.id,
                                      label:
                                        selectedServices[index]?.employee?.name,
                                    }
                                  : null
                              }
                              menuIsOpen={
                                editingEmployeeInServiceIndex === index
                              }
                            />
                          </div>
                        ) : (
                          service?.employee?.name
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <p className="font-semibold">{service.price}</p>
                  <Button
                    type="button"
                    onClick={() => onRemoveService(index)}
                    variant="light"
                    isIconOnly
                    className="rounded"
                  >
                    <TrashIcon className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            ))}
          <Select
            placeholder="+ Add service"
            components={{
              Option: CustomServiceOption,
            }}
            onChange={onHandleAddService}
            value={serviceSelectValue}
            className="text-zinc-500 text-sm"
            options={availableServices?.map((category: any) => ({
              label: category.name,
              options: category.services.map((service: any) => ({
                value: service.id,
                label: service.title,
                price: service.price,
                status: service.status,
                employee,
              })),
            }))}
          />
        </div>
        <div className="flex flex-col gap-6 w-full bottom-0 absolute">
          <Card
            className="flex flex-row items-center bg-[#FAFAFA] border-1 border-[#E4E4E7] justify-between p-4 w-full rounded-sm"
            shadow="sm"
          >
            <p>Subtotal</p>
            <p className="font-semibold">
              ${round(sumBy(selectedServices, "priceCents") / 100, 2)}
            </p>
          </Card>
          <Button
            type="submit"
            className="rounded-sm p-3 w-full bottom-0 bg-black text-white"
            isDisabled={isLoading}
          >
            {isLoading && <Spinner color="default" size="sm" />}
            Save and Continue
          </Button>
        </div>
      </form>
    </div>
  );
};
