import { selectAccount, updateAccount } from "app/redux/accountSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export const useApiKeys = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);

  const onUpdateAccount = async (updatedAccount: any) => {
    try {
      await dispatch(updateAccount(updatedAccount) as any).unwrap();
      toast.success("API details updated successfully");
    } catch (e) {
      console.error(e);
      toast.error("Failed to update API details");
    }
  };

  return { account, onUpdateAccount };
};
