import { UserAuth, UserContextType } from "app/context/AuthContext";
import { selectUser, userUpdate } from "app/redux/userSlice";
import { getFormValues } from "app/utils/getFormValues";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export const useUserDetails = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { updateUserEmail, reauthenticateUser } = UserAuth() as UserContextType;
  const [showReAuthModal, setShowReAuthModal] = React.useState(false);
  const [emailToBeUpdatedTo, setEmailToBeUpdatedTo] = React.useState("");

  const handleReauthAndSubmit = async (event: any) => {
    event.preventDefault();
    const { password } = getFormValues(event.target);
    try {
      await reauthenticateUser(password);
      await onUpdateUser({ email: emailToBeUpdatedTo });
      setShowReAuthModal(false);
    } catch (e: any) {
      console.log(e);
      if (e.code === "auth/wrong-password") {
        return toast.error("Wrong password");
      }
      toast.error("Failed to update details");
    }
  };

  const onUpdateUser = async (newUserParams: any) => {
    try {
      if (newUserParams.email) {
        setEmailToBeUpdatedTo(newUserParams.email);
        await updateUserEmail(newUserParams.email);
      }
      await dispatch(userUpdate(newUserParams) as any).unwrap();
      toast.success("Details updated successfully");
    } catch (e: any) {
      if (
        e.code === "auth/requires-recent-login" ||
        e.message.includes("CREDENTIAL_TOO_OLD_LOGIN_AGAIN")
      ) {
        return setShowReAuthModal(true);
      }
      toast.error("Failed to update details");
    }
  };

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("user[avatar]", file);

      try {
        await dispatch(userUpdate(formData) as any).unwrap();
        toast.success("Avatar updated successfully");
      } catch (error) {
        console.log("error", error);
        toast.error("Failed to update avatar");
      }
    }
  };

  return {
    user,
    onUpdateUser,
    showReAuthModal,
    setShowReAuthModal,
    handleReauthAndSubmit,
    handleAvatarChange,
  };
};
