export interface FormValues {
  [fieldName: string]: string;
}

enum CheckBoxStates {
  CHECKED = "checked",
  UNCHECKED = "unchecked",
}

const configureFormValues = (element: HTMLInputElement) => {
  const { name, type } = element;
  if (name === "email") {
    return element.value.toLowerCase();
  } else if (type === "checkbox") {
    return element.checked ? CheckBoxStates.CHECKED : CheckBoxStates.UNCHECKED;
  } else {
    return element.value;
  }
};
// Gets the values for each input field in a form
// export function getFormValues(
//   form: EventTarget & Element,
//   includeHidden: boolean = true
// ): FormValues {
//   return Array.from(form.querySelectorAll("input, select, textarea"))
//     .filter((element: any) => includeHidden || !element.hidden)
//     .reduce((formValues: FormValues, element: any) => {
//       if (element.type === "radio" && !element.checked) {
//         return formValues; // Skip unchecked radio buttons
//       }
//       return {
//         ...formValues,
//         [element.name]: configureFormValues(element),
//       };
//     }, {});
// }

export function getFormValues(
  form: EventTarget & Element,
  includeHidden: boolean = true
): FormValues {
  const formValues: FormValues = {};

  // Collect values from all inputs, selects, and textareas
  Array.from(form.querySelectorAll("input, select, textarea")).forEach(
    (element: any) => {
      if (!includeHidden && element.hidden) return;

      if (element.type === "radio" && !element.checked) return; // Skip unchecked radio buttons

      if (element.name) {
        const name = element.name;
        let value;

        if (element instanceof HTMLInputElement) {
          if (element.type === "checkbox") {
            value = element.checked
              ? CheckBoxStates.CHECKED
              : CheckBoxStates.UNCHECKED;
          } else {
            value = element.value;
          }
        } else if (element instanceof HTMLSelectElement) {
          value = element.value;
        } else if (element instanceof HTMLTextAreaElement) {
          value = element.value;
        }

        // If value exists, set it in the formValues object
        if (value !== undefined) {
          formValues[name] = value;
        }
      }
    }
  );

  // Handle RadioGroup values if they are not captured above
  Array.from(form.querySelectorAll("input[type='radio']")).forEach(
    (element: any) => {
      if (element.checked) {
        formValues[element.name] = element.value;
      }
    }
  );

  return formValues;
}

export const getInvalidKeys = (payload: Record<string, any>): string[] => {
  const invalidKeys: string[] = [];
  Object.keys(payload).forEach((key) => {
    if (
      payload[key] === null ||
      payload[key] === "" ||
      payload[key] === undefined
    ) {
      invalidKeys.push(key);
    }
  });
  return invalidKeys;
};
