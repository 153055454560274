import { inviteUser } from "app/redux/userSlice";
import { getFormValues } from "app/utils/getFormValues";
import { titleize } from "app/utils/string";
import * as React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const useInviteModal = ({
  onOpenChange,
}: {
  onOpenChange: (isOpen: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    const { email, name, role, phoneNumber } = getFormValues(event.target);
    const formattedName = titleize(name.trim());
    try {
      setIsLoading(true);
      await dispatch(
        inviteUser({ email, name: formattedName, role, phoneNumber }) as any
      ).unwrap();
      onOpenChange(false);
      toast.success(`${formattedName} invited successfully`);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(
        error.data.error || "An error occurred while inviting the user"
      );
      console.error(error);
    }
  };

  return {
    onSubmit,
    isLoading,
  };
};
