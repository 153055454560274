import { Path } from "app/path";
import { selectEmployees } from "app/redux/employeesSlice";
import { selectSales } from "app/redux/salesSlice";
import { useQuery } from "app/utils/useQuery";
import { find } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useOpenSaleScreen = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const saleIdParam = query.get("saleId");
  const screenParam = query.get("screen");
  const { sales } = useSelector(selectSales);
  // const sale = find(sales, (sale: any) => sale.id === Number(saleIdParam));
  const [sale, setSale] = useState();
  const employees = useSelector(selectEmployees);
  const employee = employees[0];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (saleIdParam) {
      setIsLoading(true);
      const selectedSale = find(
        sales,
        (sale: any) => sale.id === Number(saleIdParam)
      ) as any;
      setSale(selectedSale);
      setIsLoading(false);
    }
  }, [sales, saleIdParam]);

  return {
    employee,
    isLoading,
    sale,
    screenParam,
  };
};
