import * as React from "react";
import {
  Modal,
  Button,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import { useAvailabilitiesModal } from "./useAvailabilitiesModal";
import { format, parseISO } from "date-fns";
import { IntentScreen } from "app/staff/staffHours/components/screens/intentScreen/intentScreen";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { EditScreen } from "../screens/editScreen/editScreen";

export interface AvailabilitiesModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  availability?: any;
}

export const AvailabilitiesModal: React.FC<AvailabilitiesModalProps> = ({
  isOpen,
  onOpenChange,
  availability,
}) => {
  const {
    screenIndex,
    setScreenIndex,
    availabilityEditingIntent,
    setAvailabilityEditingIntent,
  } = useAvailabilitiesModal({
    isOpen,
  });

  const screens = [
    <IntentScreen
      setScreenIndex={setScreenIndex}
      availability={availability}
      setAvailabilityEditingIntent={setAvailabilityEditingIntent}
      screenIndex={screenIndex}
    />,
    <EditScreen
      availability={availability}
      availabilityEditingIntent={availabilityEditingIntent!}
      // dateToChange={availability?.date}
      onOpenChange={onOpenChange}
    />,
  ];

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className="rounded px-8 py-12"
      size="xl"
      disableAnimation
    >
      <ModalContent key={screenIndex}>
        <FadeInSlide direction="bottom">{screens[screenIndex]}</FadeInSlide>
      </ModalContent>
    </Modal>
  );
};
