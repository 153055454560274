import * as React from "react";

export interface CustomClientOptionProps {
  innerRef: any;
  innerProps: any;
  data: any;
}

export const CustomClientOption: React.FC<CustomClientOptionProps> = ({
  innerRef,
  innerProps,
  data,
}) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="flex items-center gap-2 p-2 cursor-pointer hover:bg-blue-200"
    >
      <div className="flex flex-col gap-1">
        <span className="font-bold text-sm text-zinc-700">{data.label}</span>
        <span className="text-sm text-gray-500">
          {data.phone || "No phone"} | {data.email || "No email"}
        </span>
      </div>
    </div>
  );
};
