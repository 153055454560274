export const formatPhoneNumber = (number: any) => {
  // Ensure the input is a string and remove any non-numeric characters
  const cleaned = ("" + number).replace(/\D/g, "");

  // Extract parts of the number based on its length
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

  // Check if the match is successful and format accordingly
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }

  return number; // Return the original number if formatting fails
};
