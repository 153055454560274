import * as React from "react";
import { Button, Input } from "@nextui-org/react";
import GreenCheckSvg from "app/assets/icons/greenCheck.svg";
import BlackXSvg from "app/assets/icons/blackX.svg";
import PenSvg from "app/assets/icons/pen.svg";
import { useEffect } from "react";
import classNames from "classnames";
import PhoneInput from "react-phone-input-2";
import { formatPhoneNumber } from "app/utils/formatPhone";

export interface InputEditProps {
  isEditing?: boolean;
  name: string;
  defaultValue?: string;
  onSave?: (value: any) => void;
  onCancel?: () => void;
  valueClassName?: string;
  type?: string;
  label?: string;
  valueShownWhenNotEditing?: any;
  isDisabled?: boolean;
}

export const InputEdit: React.FC<InputEditProps> = ({
  isEditing = false,
  name,
  defaultValue,
  onSave,
  onCancel,
  valueClassName = "",
  type = "text",
  label,
  valueShownWhenNotEditing,
  isDisabled = false,
}) => {
  const [editing, setEditing] = React.useState(isEditing);
  const [value, setValue] = React.useState(defaultValue || "");

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (onSave) {
      onSave({ [name]: value });
    }
    setEditing(false);
  };

  const handleCancel = () => {
    setValue(defaultValue || "");
    setEditing(false);
    if (onCancel) {
      onCancel();
    }
  };

  return editing ? (
    <div className="flex flex-col">
      <p className="text-zinc-500 text-sm">{label}</p>
      <form onSubmit={handleSave} className="flex items-center">
        {type === "phone" ? (
          <PhoneInput
            value={value}
            specialLabel=""
            inputProps={{ name: name }}
            onChange={(phone) => setValue(phone)}
            placeholder="+1 (555) 555-5555"
            containerClass="w-full"
            inputClass="custom-input"
          />
        ) : (
          <input
            // variant="bordered"
            // classNames={{
            //   inputWrapper:
            //     "bg-transparent border-[#F4F4F5] rounded shadow-sm border",
            // }}
            className="bg-transparent border-[#F4F4F5] rounded shadow-sm border h-10 w-full pl-3"
            // label={label}
            name={name}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type={type}
          />
        )}
        <div className="flex gap-4 py-3 pl-4">
          <Button
            isIconOnly
            className="bg-transparent rounded-none w-6 h-6 min-w-0"
            type="submit"
          >
            <img src={GreenCheckSvg} alt="check" className="" />
          </Button>
          <Button
            isIconOnly
            className="bg-transparent rounded-none w-6 h-6 min-w-0"
            onClick={handleCancel}
          >
            <img src={BlackXSvg} alt="x" className="" />
          </Button>
        </div>
      </form>
    </div>
  ) : (
    <div className="flex flex-col gap-2">
      <p className="text-zinc-500 text-sm">{label}</p>
      <div
        className={classNames("flex items-center", {
          "opacity-50": isDisabled,
        })}
      >
        <p className={valueClassName}>
          {valueShownWhenNotEditing ||
            (type === "phone" ? formatPhoneNumber(value) : value)}
        </p>
        <Button
          isIconOnly
          className="bg-transparent rounded-none w-6 h-6 min-w-0 ml-2"
          onClick={() => setEditing(true)}
          isDisabled={isDisabled}
        >
          <img src={PenSvg} alt="pen" className="w-6 h-6" />
        </Button>
      </div>
    </div>
  );
};
