import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";
import { UserRoles } from "./userSlice";

export interface EmployeesSliceState {
  id: number;
  name: string;
  email: string;
  phone: string;
  shops: any[];
  services: any[];
  invitePending?: boolean;
  role: UserRoles;
  avatar: string;
  availabilities: any[];
  archivedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

const initialState = [] as EmployeesSliceState[];

export const getEmployees = createAsyncThunk(
  "employees/getEmployeesStatus",
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get("/v1/employees");
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const createEmployee = createAsyncThunk(
// );

export const updateEmployee = createAsyncThunk(
  "employees/updateEmployeeStatus",
  async (employee: Partial<EmployeesSliceState> | any, thunkAPI) => {
    try {
      let data, config;

      if (employee.avatar instanceof FormData) {
        // If it's a FormData, send it as the payload
        data = employee.avatar;
        config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      } else {
        // If it's not a FormData, send it as JSON
        data = employee;
        config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
      }

      const response = await http.patch(
        `/v1/employees/${employee.id}`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const deleteEmployee = createAsyncThunk(
// );

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      const index = state.findIndex(
        (employee) => employee.id === action.payload.id
      );
      state[index] = action.payload;
    });
  },
});

export const selectEmployees = (state: RootState) => state.employees;
