import {
  AvailabilityTypes,
  selectAvailabilities,
} from "app/redux/availabilitiesSlice";
import { RootState } from "app/redux/store";
import { useQuery } from "app/utils/useQuery";
import { find } from "lodash";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const useAvailabilitiesModal = ({ isOpen }: { isOpen: boolean }) => {
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const [screenIndex, setScreenIndex] = React.useState(0);
  const [availabilityEditingIntent, setAvailabilityEditingIntent] =
    React.useState<AvailabilityTypes>();

  useEffect(() => {
    if (!isOpen) {
      setScreenIndex(0);
      query.delete("availabilityId");
      const newUrl = `${location.pathname}?${query.toString()}`;
      navigate(newUrl);
    }
  }, [isOpen]);

  return {
    screenIndex,
    setScreenIndex,
    availabilityEditingIntent,
    setAvailabilityEditingIntent,
  };
};
