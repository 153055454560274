import { Path } from "app/path";
import { payWithCreditCard, updateSale } from "app/redux/salesSlice";
import { CheckoutScreenTypes } from "app/sales/useSales";
import { useQuery } from "app/utils/useQuery";
import { round, set } from "lodash";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import paymentFormStyle from "./paymentFormStyle";
import { selectAccount } from "app/redux/accountSlice";
import { toast } from "react-toastify";
import { titleize } from "app/utils/string";

export const tipOptions = [
  { label: "18%", value: 0.18 },
  { label: "20%", value: 0.2 },
  { label: "22%", value: 0.22 },
];

export enum PaymentTypes {
  CASH = "cash",
  CREDIT_CARD = "credit_card",
}

export const testCreditCards = [
  {
    cardType: "Visa",
    cardNumber: "4761530001111118",
    expirationDate: "12/25", // MM/YY
    cvv: "123",
    cardholderName: "John Doe",
    zipCode: 94016,
  },
  {
    cardType: "MasterCard",
    cardNumber: "5137221111116668",
    expirationDate: "11/24", // MM/YY
    cvv: "456",
    cardholderName: "Jane Smith",
    zipCode: 94016,
  },
  {
    cardType: "Discover",
    cardNumber: "6011208701117775",
    expirationDate: "10/26", // MM/YY
    cvv: "789",
    cardholderName: "Alice Johnson",
    zipCode: 94016,
  },
  {
    cardType: "Amex",
    cardNumber: "371030089111338",
    expirationDate: "09/23", // MM/YY
    cvv: "1234", // Amex cards typically have a 4-digit CVV
    cardholderName: "Bob Brown",
    zipCode: 94016,
  },
  {
    cardType: "Diners Club",
    cardNumber: "36185900011112",
    expirationDate: "08/27", // MM/YY
    cvv: "567",
    cardholderName: "Charlie Davis",
    zipCode: 94016,
  },
  {
    cardType: "JCB",
    cardNumber: "3566002345432153",
    expirationDate: "07/25", // MM/YY
    cvv: "890",
    cardholderName: "Diana Evans",
    zipCode: 94016,
  },
];

export const usePaymentCheckout = ({ sale }: { sale: any }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const account = useSelector(selectAccount);
  const navigate = useNavigate();
  const [tipsCents, setTipsCents] = useState(0);
  const total = (
    (sale?.subtotalCents + sale?.taxCents + tipsCents) /
    100
  ).toFixed(2); // will have to change depending on tipcents
  const [processingCreditCardCharge, setProcessingCreditCardCharge] =
    useState(false);
  const [amountToCharge, setAmountToCharge] = useState(total);
  const [showPaymentIframe, setShowPaymentIframe] = useState(false);
  const [showCreditCardOptions, setShowCreditCardOptions] = useState(false);
  const cardFormRef: any = useRef(null);

  const onSubmit = async (paymentType: any) => {
    if (paymentType === PaymentTypes.CASH) {
      await onPrepaidRecording(PaymentTypes.CASH);
    } else {
      setShowCreditCardOptions(true);
    }
  };

  const openCreditCardIframe = () => {
    if (!showPaymentIframe) {
      cardFormRef.current.setStyles(paymentFormStyle);
      setShowPaymentIframe(true);
    }
  };

  const onPrepaidRecording = async (paymentMethod: PaymentTypes) => {
    const payload = {
      ...sale,
      merchantSalesId: sale.salesMerchantServices.map(
        (service: any) => service.merchantServiceId
      ),
      tipsCents,
      paymentMethod: paymentMethod,
      totalChargedAmountCents: Number(amountToCharge) * 100,
      status: "closed",
    };
    try {
      await dispatch(updateSale(payload) as any).unwrap();
      query.delete("status");
      query.delete("screen");
      query.append("screen", CheckoutScreenTypes.CLOSED);
      query.append("status", "closed");
      navigate(`${Path.SALES}?${query.toString()}`);
      toast.success(`${titleize(paymentMethod)} payment successfully recorded`);
    } catch (error) {
      console.log(error);
    }
  };

  const resetPaymentMethod = () => {
    setShowPaymentIframe(false);
    setShowCreditCardOptions(false);
  };

  const onCreditCardPayment = async (event: any) => {
    event.preventDefault();
    setProcessingCreditCardCharge(true);
    try {
      const result = await cardFormRef.current.getNonceToken();
      const creditCardRes = await dispatch(
        payWithCreditCard({
          source: `nonce-${result.nonce}`,
          amount: Number(amountToCharge),
          name: sale.client.name,
          expiryMonth: result.expiryMonth,
          expiryYear: result.expiryYear,
          tip: round(tipsCents / 100, 2),
        }) as any
      ).unwrap();

      const response = await dispatch(
        updateSale({
          ...sale,
          merchantSalesId: sale.salesMerchantServices.map(
            (service: any) => service.merchantServiceId
          ),
          tipsCents,
          paymentMethod: PaymentTypes.CREDIT_CARD,
          totalChargedAmountCents: Number(amountToCharge) * 100,
          status: "closed",
          acceptBlueTransactionId: creditCardRes.referenceNumber,
        }) as any
      ).unwrap();

      query.delete("status");
      query.delete("screen");
      query.append("screen", CheckoutScreenTypes.CLOSED);
      query.append("status", "closed");
      navigate(`${Path.SALES}?${query.toString()}`);
      setProcessingCreditCardCharge(false);
      toast.success("Credit card payment successful");
    } catch (error: any) {
      console.log(error);
      if (error?.data?.error?.status === "Declined") {
        toast.error("Card declined. Please try another card.");
        cardFormRef.current.resetForm();
      }
      if (error?.data?.error?.status === "Error") {
        toast.error("An error occurred. Please try again.");
        cardFormRef.current.resetForm();
      }
      setProcessingCreditCardCharge(false);
    }
  };

  const toOpenInfoScreen = () => {
    query.delete("screen");
    navigate(`${Path.SALES}?${query.toString()}`);
  };

  useEffect(() => {
    setAmountToCharge(total);
  }, [total]);

  useEffect(() => {
    const hostedTokenization = new window.HostedTokenization(
      account.acceptBlueTokenizationApi
    );
    cardFormRef.current = hostedTokenization.create("card-form");
    cardFormRef.current.mount("#accept-blue-iframe");
  }, []);

  return {
    tipsCents,
    setTipsCents,
    amountToCharge,
    setAmountToCharge,
    total,
    onSubmit,
    showPaymentIframe,
    setShowPaymentIframe,
    onCreditCardPayment,
    processingCreditCardCharge,
    toOpenInfoScreen,
    openCreditCardIframe,
    showCreditCardOptions,
    setShowCreditCardOptions,
    onPrepaidRecording,
    resetPaymentMethod,
  };
};
