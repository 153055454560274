import { selectAccount, updateAccount } from "app/redux/accountSlice";
import { getFormValues } from "app/utils/getFormValues";
import { set, some } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export const useBusinessDetails = () => {
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const initialFormValues: any = {
    name: account.name,
    website: account.website,
    description: account.description,
  };
  const [isFormChanged, setIsFormChanged] = useState(false);

  const onUpdateAccount = async (updatedAccount: any) => {
    try {
      await dispatch(updateAccount(updatedAccount) as any).unwrap();
      toast.success("Business details updated successfully");
    } catch (e) {
      console.error(e);
      toast.error("Failed to update business details");
    }
  };

  return {
    isFormChanged,
    account,
    onUpdateAccount,
  };
};
