import { UserAuth, UserContextType } from "app/context/AuthContext";
import { Path } from "app/path";
import { OnboardingStep } from "app/redux/onboardingSlice";
import { userSignup } from "app/redux/userSlice";
import { getFormValues } from "app/utils/getFormValues";
import { parameterize } from "app/utils/string";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { generatePath } from "react-router-dom";
import { toast } from "react-toastify";

export const useSignup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { emailPassSignUp } = UserAuth() as UserContextType;

  const handleOnSignupSubmit = async (event: any) => {
    event.preventDefault();
    try {
      setLoading(true);
      const { email, password, passwordConfirmation } = getFormValues(
        event.target
      );
      if (password !== passwordConfirmation) {
        setLoading(false);
        return toast.error("Password confirmation does not match password");
      }
      const { user } = await emailPassSignUp(email, password);
      await dispatch(
        userSignup({ email: user.email, accountType: "merchant" }) as any
      );
      setLoading(false);
      navigate(
        generatePath(Path.ONBOARDING, {
          step: parameterize(OnboardingStep.BUSINESS_INFO),
        })
      );
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return {
    loading,
    handleOnSignupSubmit,
  };
};
