import * as React from "react";
import {
  startOfWeek,
  endOfWeek,
  format,
  addWeeks,
  subWeeks,
  startOfToday,
} from "date-fns";
import { Button } from "@nextui-org/react"; // Assuming you're using NextUI's Button component
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

export interface WeekNavigatorProps {
  initialDate?: Date;
  onPrevWeek?: (newDate: Date) => void;
  onNextWeek?: (newDate: Date) => void;
}

export const WeekNavigator: React.FC<WeekNavigatorProps> = ({
  initialDate,
  onPrevWeek,
  onNextWeek,
}) => {
  const [currentDate, setCurrentDate] = React.useState<Date>(
    initialDate || startOfToday()
  );

  const start = startOfWeek(currentDate, { weekStartsOn: 0 }); // Week starts on Sunday
  const end = endOfWeek(currentDate, { weekStartsOn: 0 });

  const prevWeek = () => {
    const newDate = subWeeks(currentDate, 1);
    setCurrentDate(newDate);

    onPrevWeek?.(newDate);
  };

  const nextWeek = () => {
    const newDate = addWeeks(currentDate, 1);
    setCurrentDate(newDate);

    onNextWeek?.(newDate);
  };

  return (
    <div className="flex w-full max-w-[311px] items-center h-6 justify-between self-center">
      <Button variant="light" isIconOnly onClick={prevWeek} className="h-3 w-3">
        <ChevronLeftIcon />
      </Button>
      <div className="text-zinc-700 text-base font-semibold">
        {format(start, "MMM dd")} - {format(end, "dd, yyyy")}
      </div>
      <Button variant="light" isIconOnly onClick={nextWeek} className="h-3 w-3">
        <ChevronRightIcon />
      </Button>
    </div>
  );
};
