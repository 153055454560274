import * as React from "react";
import { useOpenSaleScreen } from "./useOpenSaleScreen";
import { NewCheckout } from "./components/newCheckout/newCheckout";
import { PaymentCheckout } from "./components/paymentCheckout/paymentCheckout";
import { CheckoutScreenTypes } from "app/sales/useSales";

export interface OpenSaleScreenProps {
  handleClientSearch: (inputValue: string, callback: any) => void;
}

export const OpenSaleScreen: React.FC<OpenSaleScreenProps> = ({
  handleClientSearch,
}) => {
  const { sale, screenParam } = useOpenSaleScreen();

  return screenParam === CheckoutScreenTypes.PAYMENT ? (
    <PaymentCheckout sale={sale} />
  ) : (
    <NewCheckout handleClientSearch={handleClientSearch} />
  );
};
