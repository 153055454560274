import classNames from "classnames";
import { format } from "date-fns";
import * as React from "react";
import { OtherEventTypes } from "app/schedules/useSchedules";
import { useCustomEvent } from "./useCustomEvent";

export const CustomEvent: React.FC<any> = ({
  event: { client, employee, shop, start, end, pending, services },
  title,
}) => {
  const { isOffTime, isCurrentShop } = useCustomEvent({
    title,
    shop,
  });

  return (
    <div
      className={classNames(
        "absolute top-0 left-0 self-center translate-x-1.5 p-[10px] w-full h-full overflow-hidden rounded-sm",
        {
          "border-2 border-[#33beff] bg-[#33bfff0d]":
            !isOffTime && isCurrentShop,
          "bg-[#F4F4F5] cursor-default": isOffTime || !isCurrentShop,
          "border-2 border-[#3F3F46]": !isOffTime && !isCurrentShop,
          "border-2 border-red-400 bg-red-100": pending,
        }
      )}
    >
      <div className="flex items-center justify-between pb-2">
        <p className="text-zinc-700 text-sm">
          {!isOffTime &&
            (!isOffTime && !isCurrentShop && !pending ? shop?.name : title)}
        </p>
        <p className="text-zinc-700 text-sm">
          {!isOffTime && format(start, "h:mm a")}
        </p>
      </div>
      {client?.name && (
        <p className="text-zinc-700 text-sm font-semibold">{client.name}</p>
      )}
    </div>
  );
};
