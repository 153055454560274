import { Button, Input, Spinner } from "@nextui-org/react";
import * as React from "react";
import { useSignin } from "./useSignin";
import { Link } from "react-router-dom";
import { Path } from "app/path";

export interface SigninProps {}

export const Signin: React.FC<SigninProps> = (props) => {
  const { handleSigninSubmit, loading } = useSignin();
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <div className="flex flex-col w-full max-w-lg gap-8 border rounded p-10 shadow-lg">
        <h1 className="text-2xl text-center">Welcome to All Set</h1>
        <form onSubmit={handleSigninSubmit} className="flex flex-col gap-4 ">
          <Input
            placeholder="Email"
            name="email"
            type="email"
            validationBehavior={"native"}
            defaultValue={"gibbsoninfo+test1@gmail.com"}
            color="primary"
            classNames={{
              inputWrapper: "custom-input",
            }}
          />
          <Input
            placeholder="Password"
            name="password"
            validationBehavior={"native"}
            defaultValue={"password123"}
            type="password"
            color="primary"
            classNames={{
              inputWrapper: "custom-input",
            }}
          />

          <Button
            isDisabled={loading}
            color="primary"
            type="submit"
            className="mt-4 bg-black rounded"
          >
            {loading && <Spinner size="sm" color="default" />}
            Signin
          </Button>
          <Button
            as={Link}
            to={Path.SIGNUP}
            variant="light"
            className="rounded hover:!bg-black hover:text-white"
          >
            Sign up
          </Button>
        </form>
      </div>
    </div>
  );
};
