import { Card, CardBody } from "@nextui-org/react";
import classNames from "classnames";
import * as React from "react";

export interface ChoiceCardProps {
  amount: number;
  title: string;
  description: string;
  setTeamSize: (size: string) => void;
  teamSize?: string;
}

const getSquares = (amount: number) => {
  const squares = [];
  for (let i = 0; i < amount; i++) {
    squares.push(
      <div
        key={i}
        className={classNames("bg-black rounded-sm", {
          "h-8 w-8": amount === 1 || amount === 3,
          "h-6 w-6": amount === 8,
          "h-2.5 w-2.5": amount === 24,
        })}
      />
    );
  }
  return squares;
};

export const ChoiceCard: React.FC<ChoiceCardProps> = ({
  amount,
  title,
  description,
  setTeamSize,
  teamSize,
}) => {
  const isSelected = teamSize === title.toLowerCase();

  return (
    <Card
      shadow="none"
      className={classNames(
        "w-[200px] h-[200px] p-5 cursor-pointer rounded-md transform transition-all duration-300 border border-zinc-200 hover:border-black",
        {
          "border-black": isSelected,
        }
      )}
      disableAnimation
    >
      <CardBody
        className="flex flex-col justify-between p-2"
        onClick={() => setTeamSize(title.toLowerCase())}
      >
        <div
          className={classNames("grid gap-4", {
            "grid-cols-1 self-center": amount === 1,
            "grid-cols-3 grid-rows-1": amount === 3,
            "grid-cols-4 grid-rows-2": amount === 8,
            "grid-cols-8 grid-rows-3": amount === 24,
          })}
        >
          {getSquares(amount)}
        </div>
        <div className="flex flex-col gap-1 items-center justify-center">
          <p className="font-semibold">{title}</p>
          <p className="font-semibold">{description}</p>
        </div>
      </CardBody>
    </Card>
  );
};
