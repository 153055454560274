import { Path } from "app/path";
import { ClientsSliceState, selectClients } from "app/redux/clientsSlice";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SortDescriptor } from "react-stately";

export const useClientTable = ({ rowsPerPage }: { rowsPerPage: number }) => {
  const {
    clients = [], // Ensure clients is always an array
    meta: { totalCount = 0 }, // Default to 0 if meta is not defined
  } = useSelector(selectClients);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>(
    {}
  );
  const query = useQuery();
  const navigate = useNavigate();
  const pageParam = query.get("page");

  const handlePageChange = (page: number) => {
    setPage(page);
    query.delete("page");
    query.append("page", page.toString());
    navigate(`${Path.CLIENTS}?${query.toString()}`);
  };

  const sortedItems = React.useMemo(() => {
    // Avoid sorting if no items or sortDescriptor is empty
    if (clients.length === 0 || !sortDescriptor.column) return clients;

    return [...clients].sort((a: ClientsSliceState, b: ClientsSliceState) => {
      const first = a[
        sortDescriptor.column as keyof ClientsSliceState
      ] as number;
      const second = b[
        sortDescriptor.column as keyof ClientsSliceState
      ] as number;
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, clients]);

  // Optionally handle loading state
  useEffect(() => {
    if (pageParam) {
      setPage(Number(pageParam));
    }
    setIsLoading(false); // Set to true when fetching data and false when done
  }, [clients, pageParam]);

  return {
    isLoading,
    setIsLoading,
    clients,
    page,
    setPage,
    sortedItems,
    sortDescriptor,
    setSortDescriptor,
    totalCount,
    handlePageChange,
  };
};
