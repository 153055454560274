import { createBusinessService } from "app/redux/businessServicesSlice";
import { getEmployees } from "app/redux/employeesSlice";
import { getFormValues } from "app/utils/getFormValues";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const useAddServiceModal = ({
  setShowAddServiceModal,
  serviceCategoryIdForNewService,
}: {
  setShowAddServiceModal: (show: boolean) => void;
  serviceCategoryIdForNewService?: number;
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const serviceCategoryId = query.get("categoryId");
  const [isValid, setIsValid] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);
  const [duration, setDuration] = React.useState<{
    duration: number;
    label: string;
  }>({ duration: 2000, label: "1 hr" });
  const onFormChange = (event: any) => {
    const { title, duration, price } = getFormValues(event.target.form);
    if (title !== "" && duration !== "" && price !== "") {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const onSubmit = async (event: any) => {
    try {
      event.preventDefault();
      setIsCreating(true);
      const { title, duration, price } = getFormValues(event.target);
      await dispatch(
        createBusinessService({
          title,
          description: "Service description",
          duration: Number(duration),
          priceCents: Number(price) * 100,
          serviceCategoryId: serviceCategoryIdForNewService,
        }) as any
      ).unwrap();
      // referesh employees
      await dispatch(getEmployees() as any).unwrap();
      setIsCreating(false);
      toast.success(`${title} service created successfully`);
      setShowAddServiceModal(false);
    } catch (e) {
      setIsCreating(false);
      console.error(e);
    }
  };

  return {
    onFormChange,
    isValid,
    onSubmit,
    duration,
    setDuration,
    isCreating,
  };
};
