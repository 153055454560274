import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";

export interface Schedule {
  employeeId: number;
  employeeName: string;
  employeeUid: string;
  date: string;
  appointments: any[];
  availability: {
    id: number;
    type: string;
    dayOrDate: string;
    timeStart: string;
    timeEnd: string;
    status: string;
    availabilityableType: string;
    availabilityableId: number;
    startOfWeek: string;
  };
}

export interface SchedulesSliceState {
  [date: string]: {
    [employeeId: number]: Schedule;
  };
}

const initialState: SchedulesSliceState = {};

export const getSchedules = createAsyncThunk(
  "schedules/getSchedulesStatus",
  async (
    { employeeIds = [], date }: { employeeIds: number[]; date: Date },
    thunkAPI
  ) => {
    try {
      const { data } = await http.get("/v1/schedules", {
        params: {
          schedules: {
            employeeIds,
            date,
          },
          snakeCase: true,
        },
      });
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const schedulesSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSchedules.fulfilled, (state, action) => {
      action.payload.forEach((schedule: Schedule) => {
        const { date, employeeId } = schedule;
        if (!state[date]) {
          state[date] = {};
        }
        state[date][employeeId] = schedule;
      });
    });
  },
});

export const selectSchedules = (state: RootState) => state.schedules;
