import * as React from "react";
import { useClientDrawer } from "../useClientDrawer";
import {
  Avatar,
  Button,
  Chip,
  Input,
  Radio,
  RadioGroup,
  Spinner,
  Textarea,
} from "@nextui-org/react";
import { format } from "date-fns";
import { InputEdit } from "app/components/inputEdit/InputEdit";
import { CameraIcon } from "@heroicons/react/24/outline"; // Import the camera icon

export interface UpdateClientProps {
  clientId: number;
}

export const UpdateClient: React.FC<UpdateClientProps> = ({ clientId }) => {
  const {
    client,
    onUserUpdate,
    profileImagePreview,
    handleProfileImageChange,
    showNoteTextArea,
    setShowNoteTextArea,
    addNewNote,
    newNoteContent,
    setNewNoteContent,
  } = useClientDrawer({ clientId });

  if (!client) {
    return <Spinner size="lg" />;
  }

  return (
    <div className="flex flex-col gap-6">
      <p className="font-semibold text-gray-800">Client detail</p>
      <div className="flex items-center gap-5 relative">
        <div className="relative group w-14 h-14">
          <Avatar
            className="rounded w-14 h-14"
            name={client.name}
            src={
              profileImagePreview ||
              client.avatar ||
              "https://i.pravatar.cc/150?u=" + client.id
            }
          />
          <input
            type="file"
            accept="image/*"
            onChange={(event) => handleProfileImageChange(event, true)}
            className="hidden"
            id="avatar-upload"
          />
          <label
            htmlFor="avatar-upload"
            className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 rounded opacity-0 group-hover:opacity-100 cursor-pointer transition-opacity"
          >
            <CameraIcon className="h-6 w-6 text-white" />
          </label>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-semibold">{client.name}</p>
          <p className="text-zinc-500 text-sm">
            Client since {format(client?.createdAt, "M.dd.yyyy")}
          </p>
        </div>
      </div>
      <InputEdit
        label="First name"
        name="firstName"
        defaultValue={client.name.split(" ")[0]}
        onSave={(value) =>
          onUserUpdate({ key: "firstName", value: value.firstName })
        }
        valueClassName="text-zinc-700 font-semibold"
      />
      <InputEdit
        label="Last name"
        name="lastName"
        defaultValue={client.name.split(" ")[1]}
        onSave={(value) =>
          onUserUpdate({ key: "lastName", value: value.lastName })
        }
        valueClassName="text-zinc-700 font-semibold"
      />
      <InputEdit
        label="Email"
        name="email"
        defaultValue={client.email}
        onSave={(value) => onUserUpdate({ key: "email", value: value.email })}
        type="email"
        valueClassName="text-zinc-700 font-semibold"
      />
      <InputEdit
        label="Phone"
        name="phone"
        defaultValue={client.phone}
        onSave={(value) => onUserUpdate({ key: "phone", value: value.phone })}
        type="phone"
        valueClassName="text-zinc-700 font-semibold"
      />
      <div className="flex flex-col gap-2">
        <RadioGroup
          label="Gender"
          name="gender"
          orientation="horizontal"
          validationBehavior="native"
          classNames={{
            wrapper: "flex justify-between w-full pr-4",
            label: "!text-zinc-700 font-semibold",
          }}
          defaultValue={client.gender}
        >
          <Radio
            color="default"
            classNames={{
              label: "!text-zinc-700 font-semibold text-md ml-4",
            }}
            value="female"
            onClick={() => onUserUpdate({ key: "gender", value: "female" })}
          >
            Female
          </Radio>
          <Radio
            color="default"
            classNames={{
              label: "!text-zinc-700 font-semibold text-md ml-4",
            }}
            value="male"
            onClick={() => onUserUpdate({ key: "gender", value: "male" })}
          >
            Male
          </Radio>
          <Radio
            color="default"
            classNames={{
              label: "!text-zinc-700 font-semibold text-md ml-4",
            }}
            value="nonBinary"
            onClick={() => onUserUpdate({ key: "gender", value: "nonBinary" })}
          >
            Non-Binary
          </Radio>
        </RadioGroup>
      </div>
      <div className="flex flex-col">
        <p className="font-semibold mb-2">Notes</p>
        {client.notes?.length > 0 ? (
          <ul className="flex flex-col list-disc pl-8 gap-2 mb-2">
            {client.notes.map((note) => (
              <li key={note.id}>
                <div className="flex gap-2 justify-between">
                  <div className="flex flex-col">
                    <p className="font-semibold">{note?.content}</p>
                    <p className="text-zinc-500 text-sm">
                      {format(note?.createdAt, "M.dd.yyyy") || "No date"}
                    </p>
                  </div>
                  <Chip size="sm">{note.createdBy.name}</Chip>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          !showNoteTextArea && <p>No notes</p>
        )}
        {showNoteTextArea && (
          <Textarea
            variant="bordered"
            labelPlacement="outside"
            placeholder="Enter new client note"
            name="note"
            onChange={(event) => setNewNoteContent(event.target.value)}
            classNames={{
              label: "!text-zinc-700 font-semibold text-md",
              inputWrapper:
                "bg-transparent rounded shadow-sm border border-[#F4F4F5] !h-[172px]",
              input: "font-semibold placeholder:font-normal",
            }}
          />
        )}
        <Button
          onClick={() =>
            !showNoteTextArea ? setShowNoteTextArea(true) : addNewNote()
          }
          className="bg-black text-white rounded-sm w-20 self-end"
          isDisabled={!newNoteContent && showNoteTextArea}
        >
          {!showNoteTextArea ? "Add note" : "Save"}
        </Button>
      </div>
    </div>
  );
};
