import * as React from "react";
import { UserSettingTypes, useUser } from "./useUser";
import { Listbox, ListboxItem } from "@nextui-org/react";
import classNames from "classnames";
import path from "path";
import { UserDetails } from "./components/userDetails/userDetails";
import { Security } from "./components/security/security";

export interface UserProps {}

export const User: React.FC<UserProps> = ({}) => {
  const { user, path, onMenuPress } = useUser();

  const view = () => {
    switch (path) {
      case UserSettingTypes.DETAILS:
        return <UserDetails />;
      case UserSettingTypes.SECURITY:
        return <Security />;
      default:
        return <div>Details</div>;
    }
  };

  return (
    <div className="flex gap-6 my-10 max-w-[750px] mx-auto">
      <Listbox
        className="max-w-[200px] p-4 rounded shadow h-fit"
        aria-label="Actions"
        onAction={(key: any) => onMenuPress(key)}
      >
        <ListboxItem
          classNames={{
            base: `${
              path === UserSettingTypes.DETAILS ? "bg-[#E4E4E4]" : ""
            } rounded-sm`,
          }}
          key={UserSettingTypes.DETAILS}
        >
          <p
            className={classNames(
              path === UserSettingTypes.DETAILS ? "font-semibold" : ""
            )}
          >
            User details
          </p>
        </ListboxItem>
        <ListboxItem
          classNames={{
            base: `${
              path === UserSettingTypes.SECURITY ? "bg-[#E4E4E4]" : ""
            } rounded-sm`,
          }}
          key={UserSettingTypes.SECURITY}
        >
          <p
            className={classNames(
              path === UserSettingTypes.SECURITY ? "font-semibold" : ""
            )}
          >
            Security
          </p>
        </ListboxItem>
      </Listbox>
      <div className="h-[90vh] overflow-scroll w-full">{view()}</div>
    </div>
  );
};
